import React, { useState, useRef, useEffect } from 'react';
import propTypes from 'prop-types';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const BasicDropdown = (props) => {
    const {
        label = "",
        placeholder = "",
        id = "basic-dropdown",
        isDisabled = false,
        value = "",
        options = [],
        onChange
    } = props;
    const [selection, setSelection] = React.useState(value);

    const selectRef = useRef(null);
    const [selectWidth, setSelectWidth] = useState(0);

    useEffect(() => {
        const updateWidth = () => {
            if (selectRef.current) {
                setSelectWidth(selectRef.current.offsetWidth);
            }
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    /**
     * Handles the selection
     * @param {Event} event 
     */
    const handleChange = (event) => {
        setSelection(event.target.value);
        onChange(event.target.value)
    };

    return (
        <Box sx={{ minWidth: 100 }}>
            <FormControl sx={{ m: 1, minWidth: 100 }} disabled={isDisabled} style={{ margin: '0', width: '100%' }}>
                <InputLabel id={`label-${id}`}>{label}</InputLabel>
                <Select
                    autoWidth
                    labelId={`label-${id}`}
                    id={`select-${id}`}
                    value={selection}
                    label={label}
                    onChange={handleChange}
                    displayEmpty
                    renderValue={(selected) => {
                        if (!selected && placeholder) {
                            return <span>{placeholder}</span>; // Placeholder text when no option is selected
                        }
                        return selected.charAt(0).toUpperCase() + selected.slice(1);
                    }}
                    ref={selectRef}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                width: selectWidth,
                            },
                        },
                    }}
                >
                    {[...options].map((option, index) =>
                    (<MenuItem key={`${index}-${option?.id}-menu`} value={option?.id}
                        disabled={(typeof option === 'object') ? (!!option?.disabled) : false}>
                        {option.label}
                    </MenuItem>
                    ))
                    }
                </Select>
            </FormControl>
        </Box>
    );
}
BasicDropdown.propTypes = {
    label: propTypes.string,
    placeholder: propTypes.string,
    id: propTypes.string,
    value: propTypes.string,
    options: propTypes.array, // array of objects with 'name' and 'id,
    isDisabled: propTypes.bool,
    onChange: propTypes.func,

}
export default BasicDropdown