import { cleanObject } from '../../helpers/helper.functions';
import instance from './axios';

const axiosInstance = instance;

/**
 * Gets the list of properties
 * @returns Object
 */
export const getProperties = async (filter = { limit: 1000, page: 1 }) => {
    const queryString = new URLSearchParams(cleanObject(filter)).toString();
    try {
        const result = await axiosInstance.get(`/properties/filtered-properties?${queryString}`);
        const data = result.data?.results.filter((prop) => prop.status === "active") || [];
        return ({ success: true, message: "Properties fetched successfully", data });
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false, message: errorMessage, data: [] });
    }
}

/**
 * Gets the list of properties
* PropertyId string
 * @returns Object
 */
export const getProperty = async (propertyId) => {
    try {
        const result = await axiosInstance.get(`/properties/${propertyId}`);
        const data = result.data || null;
        return data;
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false, message: errorMessage });
    }
}