import React from "react";
import AppIcons from "../../assets/images/icons";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Stack, Grid, TextField } from '@mui/material';

// import { Link } from "react-router-dom";





// import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';


import ListItem from '@mui/material/ListItem';

import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';












import './Services.scss';


function Services() {


  const [modalopen, setModalopen] = React.useState(false);
  const handleOpen = () => {
    setModalopen(true);
  };
  const handleClose = () => {
    setModalopen(false);
  };




  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };



  const serviceContent = [
    {
      className: 'col-lg-4 col-md-6 col-sm-6 col-xs-12',
      title: 'INSPIRING INTERIOR DESIGNS',
      icon: AppIcons.service1,
      // discription: 'Let artistry be unveiled'
    },
    {
      className: 'col-lg-4 col-md-6 col-sm-6 col-xs-12',
      title: 'Masterful Feng Shui solutions',
      icon: AppIcons.service2,
      // discription: 'Energize and align your life'
    },
    {
      className: 'col-lg-4 col-md-6 col-sm-6 col-xs-12',
      title: 'Landscaping for blissful living',
      icon: AppIcons.service3,
      // discription: 'When nature becomes the canvas'
    },
    {
      className: 'col-lg-4 col-md-6 col-sm-6 col-xs-12',
      title: 'Architectural design at its Best',
      icon: AppIcons.service4,
      // discription: 'Designing the extraordinary together'
    },

  ];

  return (
    <>
      <div className="service-grid-wrp f-wrp">
        <div className="row">
          {serviceContent.map((element, key) => (
            <div className={`${element.className} each-blk-sec`} key={key}>
              <div className="each-service-blk f-wrp">
                {element.icon && <span className='serv-img'><img src={element.icon} alt="services" /></span>}
                <div className='cont-blk'>
                  <h2>{element.title}</h2>
                  <p>{element.discription}</p>
                  <Button onClick={handleOpen} className='banner-btn'>Learn more</Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>


      <div className="modal-learn-more-wrp f-wrp">
        <Modal open={modalopen} onClose={handleClose}>
          <Box >
            <div className="learn-more-form f-wrp">

              <div className="form-header">
                <h2>Learn more</h2>
                <Button onClick={handleClose} className='close-btn'><svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.53329 25.8334L6.66663 23.9667L14.1333 16.5001L6.66663 9.03341L8.53329 7.16675L16 14.6334L23.4666 7.16675L25.3333 9.03341L17.8666 16.5001L25.3333 23.9667L23.4666 25.8334L16 18.3667L8.53329 25.8334Z" fill="#1D1D1B" />
                </svg>
                </Button>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <List
                      sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}
                      component="nav"
                      aria-labelledby="nested-list-subheader" className="selectList"
                    >
                      <ListItemButton onClick={handleClick}>
                        <ListItemText primary="Select from list" />
                        {open ? <span className="reverse"><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 12L16 20L24 12" stroke="#1D1D1B" strokeWidth="2.66667" strokeLinecap="round" stroke-linejoin="round" />
                        </svg>
                        </span> : <span><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 12L16 20L24 12" stroke="#1D1D1B" strokeWidth="2.66667" strokeLinecap="round" stroke-linejoin="round" />
                        </svg>
                        </span>}
                      </ListItemButton>
                      <Collapse in={open} timeout="auto" unmountOnExit>

                        <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }} className="serviceList">
                          {['Interior design',
                            'Feng Shui · Vastu Shastra',
                            'Architectural design',
                            'Services for developers',
                            'Services for investors',
                            'Other',].map((value) => {
                              const labelId = `${value}`;

                              return (
                                <ListItem
                                  key={value}
                                  secondaryAction={
                                    <IconButton edge="end" aria-label="comments">

                                    </IconButton>
                                  }
                                  disablePadding
                                >
                                  <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                    <ListItemText id={labelId} primary={`${value}`} />
                                    <ListItemIcon>
                                      <Checkbox
                                        edge="start"
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                      />
                                    </ListItemIcon>
                                  </ListItemButton>
                                </ListItem>
                              );
                            })}
                        </List>
                      </Collapse>
                    </List>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField type='text'
                      defaultValue=""
                      name="name" label="Name*" />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField type="number"
                      id="phone"
                      label="Phone*"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField
                      id="email"
                      label="Email*"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField type='text'
                      multiline
                      rows={4}
                      name="message" label="Message*" defaultValue="" />
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <Button className='submit-btn'>Submit</Button>
                  </Stack>
                </Grid>
              </Grid>
              <p className="info-txt">Not finding what you are looking for? Contact us on <span onClick={() => window.open('https://wa.me/971508880091', '_blank')}>Whatsapp</span> or  <span onClick={() => onclick = "window.location.href = 'tel:971508880091';"}>Call</span>.</p>
            </div>
          </Box>
        </Modal>
      </div>



    </>
  );
}

export default Services;