import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AppIcons from '../../assets/images/icons';
import MenuList from '@mui/material/MenuList';
import { Button, MenuItem, TextField } from '@mui/material';
import { Link } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';

import { showSnackbar } from '../Notification/Snackbar';
import { postQuery } from '../../services/api/enquires';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
import whatsappBlk from '../../assets/images/icons/Whatsapp-foot.svg';
import './footer.scss';
import SVGIcons from '../../assets/images/icons/svgIcons';


const Footer = () => {

    const location = useLocation();
    const [currentURL, setCurrentURL] = useState('')

    const [formData, setFormData] = React.useState({});
    /**
     * sets the form data 
     * @param {string} name the name of the field
     * @param {string} value  the value of the field
     */
    const setFormValue = (name, value) => {
        const formDataAs = { ...formData };
        setFormData({ ...formDataAs, ...{ [name]: value } });
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.email) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        if (isValidForm()) {
            const formPostData = {
                email: formData.email,
            }

            postQuery(formPostData).then((data) => {
                if (data.success) {
                    setFormData(null);
                    showSnackbar().success(data.message)
                } else {
                    showSnackbar().failure(data.message)

                }
            });
        }
    };

    useEffect(() => {
        setCurrentURL(location.pathname); // to set the current url
    }, [location.pathname])


    const url = window.location.origin;

    return (

        <>
            {currentURL === '/' ? <div className='footer-revamp-wrp f-wrp #e8e8ec'>
                <div className='main-footer f-wrp'>
                    <div className='container'>
                        <div className='foot-link-wrp f-wrp'>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <div className="foot-logo-box f-wrp">
                                        <div className="logo-wrp f-wrp">
                                            <span><img src={AppIcons.footerLogo} alt="logo" /></span>
                                            <p>The Place, 3rd Floor, Dubai Chambers, Dubai, U.A.E.</p>
                                            <br />
                                            <p className='contact'><SVGIcons.PhoneIcon2 /> +971-4-396-1675</p>
                                            <p className='contact'><SVGIcons.MailIcon2 /> support@psre.ae</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                    <div className="each-foot-link f-wrp">
                                        <h4 className="foot-sub-head">Quick Links</h4>
                                        <ul>
                                            <li><NavHashLink smooth to={'#home'}>Home</NavHashLink></li>
                                            <li><NavHashLink smooth to={'#About-Us'}>About</NavHashLink></li>
                                            <li><NavHashLink smooth to={'#properties'}>Listings</NavHashLink></li>
                                            <li><NavHashLink smooth to={'#blogs'}>Blogs</NavHashLink></li>
                                            <li><NavHashLink smooth to={'#become-an-agent'}>Become an Agent</NavHashLink></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                    <div className="each-foot-link f-wrp">
                                        <h4 className="foot-sub-head">Discovery</h4>
                                        <ul>
                                            <li><Link to={'/'}>Properties</Link></li>
                                            <li><Link to={'/'}>Partners</Link></li>
                                            <li><Link to={'/'}>Developers</Link></li>
                                            <li><Link to={'/'}>Team</Link></li>
                                            <li><Link to={'/'}>Contacts</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                    <div className="each-foot-link f-wrp">
                                        <h4 className="foot-sub-head">For Concerns and
                                            Inquiries.</h4>

                                        <div className='email-subscription-box f-wrp'>
                                            <TextField
                                                id="email-subscription"
                                                name="email-subscription"
                                                placeholder="Email Address"
                                                type="email"
                                                fullWidth
                                                onChange={(e) => setFormValue('email', e.target.value)}
                                            />
                                            <Button className='subscription-btn' disabled={!isValidForm()} onClick={onSubmit}><SVGIcons.RoundArrowIcon /></Button>
                                        </div>

                                        <h4 className="foot-sub-head f-wrp" style={{ paddingTop: '20px' }}>Follow Us on</h4>

                                        <div className='social-list f-wrp'>
                                            <ul style={{ justifyContent: 'flex-start' }}>
                                                <li><span onClick={() => window.open('/', '_blank')}><SVGIcons.TwitterIcon /></span></li>
                                                <li><span onClick={() => window.open('', '_blank')}><SVGIcons.YoutubeIcon /></span></li>
                                                <li><span onClick={() => window.open('https://www.linkedin.com/company/propshop-real-estate/')}><SVGIcons.LinkedInIcon /></span></li>
                                                <li><span onClick={() => window.open('https://www.facebook.com/profile.php?id=100090850307997')}><SVGIcons.FaceBookIcon /></span></li>
                                                <li><span onClick={() => window.open('https://www.instagram.com/psre.ae/', '_blank')}><SVGIcons.InstagramIcon /></span></li>
                                            </ul>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='copy-foot f-wrp'>
                    <div className='container'>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="copy-txt f-wrp">
                                    <p>© Propshop – All rights reserved 2024 </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className='link-list f-wrp'>
                                    <ul>
                                        <li><Link to={'/terms-and-conditions'}>Terms and Conditions</Link></li>
                                        <li><Link to={'/privacy-policy'}>Privacy Policy</Link></li>
                                        <li>Disclaimer</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div> :
                <div className="project-main-footer f-wrp">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <div className="foot-logo-wrp f-wrp">
                                    <div className="logo-wrp f-wrp">
                                        <span><img src={AppIcons.footLogo} alt="Propshop Logo" /></span>
                                        <span className='foottag'><img src={AppIcons.foottag} alt="Propshop tag-line" /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                        <div className="each-foot-link align-class1 f-wrp">
                                            <label className="foot-sub-head">Platform</label>
                                            <MenuList>
                                                <MenuItem><Link to={'/exclusive-listings'}>Listings</Link></MenuItem>
                                                {/* <MenuItem>Off-Plan</MenuItem> */}
                                                {/* <MenuItem><Link to={'/match-making'}>Match-making</Link></MenuItem> */}
                                                {/* <MenuItem><Link to={`${url}/#testimonials`}>Testimonials</Link></MenuItem> */}

                                                <NavHashLink smooth to={`/traditional/#testimonials`} >
                                                    <MenuItem><span>Testimonials</span></MenuItem>
                                                </NavHashLink>


                                            </MenuList>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">

                                        <div className="each-foot-link align-class2 f-wrp">
                                            <label className="foot-sub-head">Services</label>
                                            <MenuList>
                                                <MenuItem><a href={`${url}/fractional`}>Fractional ownership</a></MenuItem>
                                                <NavHashLink smooth to={`/traditional/#Services`} >
                                                    <MenuItem><span>Interior design</span></MenuItem>
                                                </NavHashLink>
                                                <NavHashLink smooth to={`/traditional/#Services`} >
                                                    <MenuItem><span>Architectural design</span></MenuItem>
                                                </NavHashLink>
                                                <NavHashLink smooth to={`/traditional/#Services`} >
                                                    <MenuItem><span>Landscaping</span></MenuItem>
                                                </NavHashLink>
                                                <NavHashLink smooth to={`/traditional/#Services`} >
                                                    <MenuItem><span>Fengshui</span></MenuItem>
                                                </NavHashLink>
                                                <NavHashLink smooth to={`/traditional/#OtherServices`} >
                                                    <MenuItem><span>Other services</span></MenuItem>
                                                </NavHashLink>
                                            </MenuList>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">

                                        <div className="each-foot-link align-class3 f-wrp">
                                            <label className="foot-sub-head">Collaborators</label>
                                            <MenuList>
                                                <NavHashLink smooth to={`/traditional/#TrustedPartners`} >
                                                    <MenuItem><span>Trusted partners</span></MenuItem>
                                                </NavHashLink>
                                                <NavHashLink smooth to={`/traditional/#TopDevelopers`} >
                                                    <MenuItem><span>Top developers</span></MenuItem>
                                                </NavHashLink>
                                            </MenuList>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                        <div className="each-foot-link align-class4 f-wrp">
                                            <label className="foot-sub-head">Company</label>
                                            <MenuList>
                                                <MenuItem onClick={() => window.open('https://wa.me/971508880091', '_blank')}>Contact Us</MenuItem>
                                                <MenuItem><Link to={'/about-us'} >About Us</Link></MenuItem>
                                            </MenuList>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 foot-cpy-sec">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                        <div className="copy-txt f-wrp">
                                            <p>© 2023 Propshop Real Estate </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="copy-txt f-wrp">
                                            <p>Al Fattan Plaza Office 508/509, Al Garhoud Airport Road, Dubai, United Arab Emirates</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 each-foot-link">
                                        <div className='social-list f-wrp'>
                                            <ul>
                                                <li><span onClick={() => window.open('https://www.instagram.com/propshop.ai', '_blank')}><img src={AppIcons.insta} alt="instagram" /></span></li>
                                                <li><span onClick={() => window.open('https://www.facebook.com/profile.php?id=100090850307997', '_blank')}><img src={AppIcons.facebook} alt="facebook" /></span></li>
                                                <li><span onClick={() => window.open('https://www.linkedin.com/company/propshop-real-estate/')}><img src={AppIcons.linkedin} alt="linkedin" /></span></li>
                                                <li><span onClick={() => window.open('https://www.tiktok.com/@sarsoorah')}><img src={AppIcons.ticktok} alt="ticktok" /></span></li>
                                                <li><span onClick={() => window.open('https://wa.me/971508880091', '_blank')}><img src={whatsappBlk} alt="whatsapp" /></span></li>
                                                {/* <li><span to={'/'} ><img src={AppIcons.snapchat} alt="icon" /></span></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>



    )
}

export default Footer;