import { showSnackbar } from '../../Components/Notification/Snackbar';
import instance from './axios';

const axiosInstance = instance;

export const uploadImage = async (file, callBackFn = () => { }) => {
    showSnackbar({ timeout: 5000000 }).info(`Uploading started...`);
    const _formData = new FormData();
    _formData.append('input_files', file);
    let _res;
    await axiosInstance({
      method: 'post',
      url: "s3-upload",
      data: _formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        const p = Math.round(((progressEvent.loaded / progressEvent.total) * 50) * 2);
        const notify = document.getElementsByClassName('nx-message');
        if (notify && notify[0]) {
          notify[0].innerHTML = `Uploading ${p}% completed`;
        }
        if (p > 99) {
            showSnackbar({ timeout: 2000 }).success(`Upload completed successfully!`);
        }
      }
    })
      .then(res => {
        _res = res.data;
        const result = _res && _res.files ? {success: true, fileKey: _res.files?.key, tempURL: _res.files?.signedUrl} : {success: false};
        callBackFn(result);
      })
      .catch(() => {
        callBackFn({success: false});
        alert('Error while uploading image');
      });
    return _res && _res.files;
  };

  
/**
 * get a file by fileKey
 * @param {Object} key the key of the file to get details
 */
export const getFileByKey = async (key) => {
  try {
      const result = await axiosInstance.get(`/s3-upload?fileKey=${key}`);        
      return {
          success: true,
          data: result.data
      };
  } catch (e) {
      const errorMessage = e.response?.data?.message;
      return ({ success: false , message: errorMessage});
  }
}