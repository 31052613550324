import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { Link } from "react-router-dom";
import { getProperties } from "../../../services/api/property";
import { showSnackbar } from '../../../Components/Notification/Snackbar';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import SVGIcons from '../../../assets/images/icons/svgIcons';

import './propertyList.scss';



function PropertyList() {

  const [data, setData] = useState([]);
  const [type, setType] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  /**
  the legendary use effect function
  This will run very first on render
   */
  useEffect(() => {
    getPropertiesAs()
    return () => {
      setData([])
    };
    // eslint-disable-next-line
  }, [type]);

  // get users from api
  const getPropertiesAs = () => {
    setIsLoading(true);
    const filter = type !== 'all' ? { operationType: type, limit: 1000 } : undefined;
    getProperties(filter)
      .then(({ data }) => setData(data))
      .catch(() => showSnackbar().failure('Something went wrong'))
      .finally(() => setIsLoading(false))

  }

  const generateSlug = (title) => {
    return title
      .replace(/\s+/g, '_') // Replace spaces with dashes
  };


  // eslint-disable-next-line react/prop-types
  const CustomPrevButton = ({ onClick }) => (
    <button type='button' className="swiper-button-prev" onClick={onClick}>
      <SVGIcons.LeftArrowIcon />
    </button>
  );
  // eslint-disable-next-line react/prop-types
  const CustomNextButton = ({ onClick }) => (
    <button type='button' className="swiper-button-next" onClick={onClick}>
      <SVGIcons.RightArrowIcon />
    </button>
  );
  const swiperRef = useRef(null);


  return (
    <>
      <div className="item-listing-wrp f-wrp">
        <div className='filter-btns f-wrp'>
          {['all', 'sell', 'rent'].map((btn) => <Button onClick={() => setType(btn)} className={`pln-btn ${type === btn ? 'active' : ''}`}>{btn}</Button>)}
        </div>
        {(isLoading) ? <span className='property-loader'> <SVGIcons.LoaderIcon /></span> :
          (!isLoading && data.length === 0) ? <span className='property-loader'> No Results found </span> :
            <>
              <Swiper
                onSwiper={(swiper) => { swiperRef.current = swiper; }}
                modules={[Navigation, Autoplay, Pagination]}
                spaceBetween={30}
                slidesPerView={3}
                pagination={{
                  el: '.swiper-pagination-custom',
                  clickable: true,
                  dynamicBullets: true,
                  dynamicMainBullets: 3,
                }}
                className='property-slider f-wrp'
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                loop
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    navigation: true,
                    autoplay: true,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    navigation: true,
                    autoplay: true,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    navigation: true,
                    autoplay: true,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                    navigation: true,
                  },
                  1275: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                    navigation: true,
                    autoplay: false,
                  },
                }}

              >
                {data.map((element, key) => (
                  <SwiperSlide key={key} className={`${element.className}`}>
                    <div className="each-property-items f-wrp" key={key}>
                      <Link to={`/property-detail/${generateSlug(element.title)}`}>
                        <div className="propList-bg f-wrp">
                          {element.images.length > 0 ? <img src={element.images[0].tempURL} alt={`${element.title}`} /> : ''}
                          <div className='labels'>
                            {(element?.isNewListing) ? <span className='propLabel newList'><SVGIcons.NewHomeIcon /> Exclusive</span> : null}
                            {(element?.isDiscountedPrice) ? <span className='propLabel discount-price'><SVGIcons.DollarIcon /> Distress Property</span> : null}
                          </div>
                        </div>
                        <div className="propList-item-blk f-wrp">
                          <h1>{element.price} AED</h1>
                          <p><b>{element.title}</b></p>
                          <p>{element.location}</p>
                          <ul className="detail-list">
                            {element.noOfBedrooms ? <li><span><SVGIcons.BedIcon /> {`${element.noOfBedrooms} Beds`}</span></li> : null}
                            {element.noOfBathrooms ? <li><span><SVGIcons.BathIcon /> {`${element.noOfBathrooms} Bath`}</span></li> : null}
                          </ul>
                        </div>
                      </Link>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="swiper-pagination-custom" style={{ paddingTop: '25px' }} />
              <CustomPrevButton onClick={() => swiperRef.current.slidePrev()} />
              <CustomNextButton onClick={() => swiperRef.current.slideNext()} />
            </>}
      </div>
    </>
  );
}

export default PropertyList;