import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import { showSnackbar } from '../../Notification/Snackbar';
import ApiCaller from '../../../services/api/APICaller';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import SVGIcons from '../../../assets/images/icons/svgIcons';

import './blogList.scss';
import S3ImageViewerWithFallback from '../../awsS3ImageViewer/ImgViewerWithFallback';



function BlogList() {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  /**
  the legendary use effect function
  This will run very first on render
   */
  useEffect(() => {
    getDataArr();
  }, [])

  const getDataArr = () => {
    const apiCaller = new ApiCaller('blog/latest-blogs');
    apiCaller.getList().then(({ data }) => {
      setData(data.result)
    })
      .catch(() => showSnackbar().failure('Something went wrong'))
      .finally(() => setIsLoading(false));
  }


  // eslint-disable-next-line react/prop-types
  const CustomPrevButton = ({ onClick }) => (
    <button type='button' className="swiper-button-prev" onClick={onClick}>
      <SVGIcons.LeftArrowIcon />
    </button>
  );
  // eslint-disable-next-line react/prop-types
  const CustomNextButton = ({ onClick }) => (
    <button type='button' className="swiper-button-next" onClick={onClick}>
      <SVGIcons.RightArrowIcon />
    </button>
  );
  const swiperRef = useRef(null);
  // const [navigationEnabled, setNavigationEnabled] = useState(false);



  const formatDateMon = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      month: 'short',
      // day: 'numeric',
    });
  };
  const formatDateDay = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      // month: 'short',
      day: 'numeric',
    });
  };

  const [multipliedData, setMultipliedData] = useState([]);

  useEffect(() => {
    if (data.length > 3) {
      setMultipliedData([...data, ...data, ...data]);
    } else {
      setMultipliedData([...data]);
    }
  }, [data]);
  



  return (
    <>
      {(isLoading) ? "Loading blogs" :
        <>
          {(!isLoading && data?.length === 0) ? "No Results found" :
            <div className="item-listing-wrp blogListing-wrp f-wrp">
              <Swiper
                onSwiper={(swiper) => { swiperRef.current = swiper; }}
                modules={[Navigation, Autoplay, Pagination]}
                spaceBetween={30}
                slidesPerView={3}
                pagination={{
                  el: '.swiper-pagination-custom',
                  clickable: true,
                  dynamicBullets: true,
                  dynamicMainBullets: 3,
                }}
                className='property-slider blog-slider f-wrp'
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                loop
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    navigation: true,
                    autoplay: true,
                    pagination: {
                      dynamicMainBullets: 1,
                    },
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    navigation: true,
                    autoplay: true,
                    pagination: {
                      dynamicMainBullets: 1,
                    },
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    navigation: false,
                    autoplay: true,
                    pagination: {
                      dynamicMainBullets: 1,
                    },
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                    navigation: false,
                  },
                  1275: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                    navigation: false,
                    autoplay: false,
                  },
                }}

              >
                {multipliedData.map((element, key) => (
                  <SwiperSlide key={key} className={`${element.className}`}>
                    <div className="each-property-items f-wrp" key={key}>
                      <Link to={`/blog-details/${element?.id}`}
                        state={{ element }}
                        className='each-article-box'>
                        <div className="propList-bg f-wrp">
                          {element.image && <S3ImageViewerWithFallback fileKey={element.image} />}
                          <span className='propLabel'><b>{formatDateDay(element.createdAt)}</b>{formatDateMon(element.createdAt)}</span>
                        </div>
                        <div className="propList-item-blk blog-itm-block f-wrp">
                          <div className='con-box'>
                            <h1>{element.title}</h1>
                            <p>{element.shortDescription}</p>
                          </div>
                          <button className='img-btn' type='button'><SVGIcons.RoundArrowIcon /></button>
                        </div>
                      </Link>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="swiper-pagination-custom" style={{ paddingTop: '25px' }} />
              <CustomPrevButton onClick={() => swiperRef.current.slidePrev()} />
              <CustomNextButton onClick={() => swiperRef.current.slideNext()} />
            </div>
          }
        </>
      }
    </>
  );
}

export default BlogList;