import { atomWithStorage } from 'jotai/utils';
import config from '../config/config';

const {tokenKey} = config;
/**
 * Initial auth state
 */
export const initialAuthState = {
    isLoggedIn: false,
    id: '',
    role: '',
    email: '',
    firstName: '',
    lastName: '',
    profilePic: '',
    status: ''
}
/**
 * auth state atom
 */
export const authState = atomWithStorage(`${tokenKey}-auth`, initialAuthState);