import { atom } from 'jotai';


/**
 * Initial property state
 */
export const initialPropertyState = {
    sortBy: '',
    limit: 1000,
    page: 1,
    filter: {
        location: '',
        distance: '',
        latitude: '',
        longitude: '',
        operationType: '',
        completionStatus: '',
        category: '',
        subcategory: '',
        minArea: '',
        maxArea: '',
        minBedrooms: '',
        maxBedrooms: '',
        minPrice: '',
        maxPrice: '',
        isDiscountedPrice: undefined,
        isNewListing: undefined,
        status: '',
    }
}
/**
 * property state atom
 */
export const propertyState = atom(initialPropertyState);