import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import AppIcons from "../../../assets/images/icons";
import BackArrow from '../../../assets/images/icons/BackArrow.svg';

import { ScrollToTopOnMount } from "../../../Components/ScrollToTop/ScrollToTop";
import ContactPopup from "../../../Components/ContactPopup/ContactPopup";
import ApiCaller from '../../../services/api/APICaller';
import "./blogs.scss";

import S3ImageViewerWithFallback from '../../../Components/awsS3ImageViewer/ImgViewerWithFallback';

const Blogs = () => {
  ScrollToTopOnMount();

  const [dataArr, setDataArr] = useState([]);

  useEffect(() => {
    getDataArr();
  }, [])

  const getDataArr = () => {
    const apiCaller = new ApiCaller('blog/latest-blogs');
    apiCaller.getList().then(({ data }) => {
      setDataArr(data.result)
    })
  }

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      // weekday: 'long', hour: '2-digit', minute: '2-digit',second: '2-digit',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };







  return (
    <div className="page-main-wrapper about-main-wrapper article-main-wrapper f-wrp">

      <div className='main-banner-wrp inner-banner f-wrp'>
        <label>Blogs</label>
        <div className='banner-bg f-wrp'>
          <img src={AppIcons.banner} alt='buildings' />
        </div>
      </div>
      <div className="breadcrumb f-wrp">
        <div className="container">
          <Link to={"/"}><img src={BackArrow} alt="back-arrow" />Home</Link>
        </div>
      </div>

      <div className="container">
        <div className='article-main-block f-wrp'>
          <div className="row">
            {dataArr.map((element, key) => (
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12" key={key}>
                <div className='article-list-box f-wrp'>
                  <Link to={`/blog-details/${element?.id}`}
                    state={{ element }}
                    className='each-article-box'>
                    <span className='article-img'>
                      {/* <img src={element.image} alt="team members" /> */}
                      {element.image && <S3ImageViewerWithFallback fileKey={element.image} />}
                    </span>
                    <div className='article-detail-box'>
                      <div className='con-box'>
                        {element.title && <span>{element.title}</span>}
                        {element.shortDescription && <p>{element.shortDescription}</p>}
                      </div>
                      <div className='info-box'>
                        {<p>{element.likes.length} Like{element?.likes.length > 1 ? 's' : ''}</p>}
                        <p style={{ marginTop: '10px', fontSize: '12px' }}>{formatDate(element.createdAt)}</p>
                      </div>


                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>

        <br></br>
        <div className="article-cont-wrp f-wrp">
          {/* <label>QUESTIONS?</label> */}
          <div className="contact-block-wrp f-wrp">
            <ContactPopup />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
