import React from 'react';
import { Stack } from '@mui/material';


export const PasswordRequirementCheckBoxes = ({ rules }) => {
    
    return (
        <Stack className='password-requirement-points' variant='div' spacing={3} style={{ marginTop: '0' }}>
            <ul>
                <li className={rules.eightCharacter ? 'checked' : ''}>At least 8 characters long</li>
                <li className={rules.containNumber ? 'checked' : ''}>1 number</li>
                <li className={rules.containUpperCase ? 'checked' : ''}>1 uppercase character</li>
                <li className={rules.containSpecialChar ? 'checked' : ''}>1 special symbol</li>
            </ul>
        </Stack>
    )
}