import React, { useEffect, useState } from 'react';
import { showSnackbar } from '../../Components/Notification/Snackbar';
import ApiCaller from '../../services/api/APICaller';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Navigation, Autoplay } from "swiper";
import S3ImageViewerWithFallback from '../awsS3ImageViewer/ImgViewerWithFallback';
import './TrustedPartners.scss';


function TrustedPartners() {
  
  const apiCaller = new ApiCaller('settings');
  const [trustedPartnersImages, setTrustedPartnersImages] = useState([]);
  const [trustedPartnersSlideCount, setTrustedPartnersSlideCount] = useState([]);

  useEffect(() => {
    getData();
    return () => {
      setTrustedPartnersImages({});
    };
    // eslint-disable-next-line
  }, []);


  const getData = () => {
    apiCaller.getData('/our-trusted-partners')
      .then((data) => {
        if (data?.optionValue) {
          setTrustedPartnersImages(data.optionValue);
        }
      })
      .catch(() => showSnackbar().failure('Something went wrong'))
  }



  useEffect(() => {
    if (trustedPartnersImages.length > 4) {
      setTrustedPartnersSlideCount((prevState) => [
        ...trustedPartnersImages,
        ...trustedPartnersImages,
        ...trustedPartnersImages,
      ]);
    } else {
      setTrustedPartnersSlideCount(trustedPartnersImages);
    }
  }, [trustedPartnersImages]);

  return (
    <>
      <Swiper
        // spaceBetween={5}
        centeredSlides={true}
        slidesPerView={1}
        speed={1500}
        loop
        autoplay={{
          delay: 700,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Navigation]}
        breakpoints={{
          500: {
            slidesPerView: 1,
            spaceBetween: 0,
            speed: 1000,
            autoplay: {
              delay: 800,
            },
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 0,
            speed: 1000,
            autoplay: {
              delay: 800,
            },
          },
          800: {
            slidesPerView: 2,
            spaceBetween: 0,
            speed: 1000,
            autoplay: {
              delay: 800,
            },
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        className="Swiperpartner-slide" >
        {(trustedPartnersSlideCount.length > 0) ? <>
          {trustedPartnersSlideCount.map((element, key) => (
            <SwiperSlide className='partner-slide' key={key}>
              <div className="each-partner-sec">
                <span className='partners'>
                  <S3ImageViewerWithFallback key={`file-${element}`} fileKey={element} />
                </span>
              </div>
            </SwiperSlide>
          ))}
        </> : null}
      </Swiper>
    </>
  );
}

export default TrustedPartners;