import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SVGIcons from '../../assets/images/icons/svgIcons';
import LandingDrawer from './mainHeader/mainHeaderDrawer';



const Header = () => {
  const [scroll, setScroll] = useState(false);
  const location = useLocation();
  const [currentURL, setCurrentURL] = useState('')
  const urlSeparator = currentURL.split('/')[1];

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 20)

    })
    setCurrentURL(location.pathname); // to set the current url
  }, [location.pathname])




  return (
    <>
    {/* {currentURL === '/' ? <> */}
    
    <div className='header-revamp-wrp f-wrp'>
      <div className='header-info-ribbon f-wrp'>
        <div className='container'>
          <ul>
            <li><SVGIcons.MapPointer /> Dubai Chambers, Dubai, UAE</li>
            <li><a href="tel:+97143961675"><SVGIcons.PhoneIcon /> +971-4-396-1675</a></li>
            <li><a href="mailto:support@psre.ae"><SVGIcons.MailIcon /> support@psre.ae</a></li>
          </ul>
        </div>
      </div>
    </div>
      <LandingDrawer />

      {/* <div className='header-nav-wrp f-wrp'>
        <LandingDrawer />
      </div> */}
       {/* : <div className={`${scroll ? "main-header scrolled" : "main-header"} project-main-header ${currentURL === '/' ? 'inside-home-wrp' : 'outside-home-wrp'} ${urlSeparator === 'fractional' ? 'inside-fractional-wrp' : null} ${urlSeparator === 'traditional' ? 'inside-traditional-wrp' : null} f-wrp`}>
         <div className="container">
           <DrawerAppBar />
         </div>
       </div>}</> */}

    </>



  )
}

export default Header;