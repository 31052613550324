import React from 'react';
import { Button, Typography, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function PasswordResetMailSuccess() {
  return (
    <>
      <div className='auth-page-layout auth-single-pg f-wrp'>
        <div className='container'>
          <div className='flex-box'>
            <div className='form-sec-wrp'>
              <div className='auth-form f-wrp'>
                <Stack className='form-tile' style={{ paddingBottom: '0' }}>
                  <Typography variant="h3" fontStyle={'normal'} fontWeight={'400'}>Password reset</Typography>
                  <Typography variant="h4" fontStyle={'normal'} fontWeight={'400'}>Password reset email sent successfully. Please check your inbox for more information</Typography>
                </Stack>
                <Link to={'/login'}>
                  <Button
                    style={{ width: '100%' }}
                    loadingPosition='start'
                    className='auth-btn'>
                    Back to login
                  </Button>
                </Link>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
