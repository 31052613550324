import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Grid, Slider } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';

import './filterForm.scss'
import BasicDropdown from '../../Dropdowns/basic.dropdown';
import { PropertyCategories } from '../../../models/property.model';
import { propertyState } from '../../../state/property.state';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import MapComponent from '../../MapSection';




function FilterForm({formKey, onFilter }) {
  const formData = useAtomValue(propertyState);
  const setFormData = useSetAtom(propertyState);
  const { filter } = formData;

  /**
 * sets the form data 
 * @param {string} name the name of the field
 * @param {string} value  the value of the field
 */
  const setFormValue = (name, value) => {
    setFormData((prv) => ({ ...prv, filter: { ...prv.filter, [name]: value } }));
  }


  const [openPPForm, setOpenPPForm] = useState(false);

  const handleOpenPPForm = () => {
    setOpenPPForm(true);
  };
  const handleClosePPForm = () => {
    setOpenPPForm(false);
  };
  const navigate = useNavigate();

  const onSearch = () => {
    if (onFilter) {
      onFilter()
    } else {
      navigate(`/exclusive-listings?mode=filtered`);
    }
    setOpenPPForm(false);
  }

  // Effect to reset category when formKey changes
  useEffect(() => {
    // setFormData((prv) => ({ ...prv, filter: initialPropertyState.filter }));
    // eslint-disable-next-line
  }, [formKey]); // Dependency array includes formKey


  return (
    <form >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <MapComponent
          key={`map-type-${formKey}`}
            showSearchInput
            showMap={false}
            initialData={{
              location: filter.location,
              latitude: 0,
              longitude: 0
            }}
            inputProps={{
              id: 'location',
              label: '',
              name: 'location',
              placeholder: 'Location, Community, Etc.'
            }}
            handleSelection={(location) => {
              setFormValue('location', location?.location)
              setFormValue('latitude', location?.latitude)
              setFormValue('longitude', location?.longitude)
            }}
          />
        </Grid>








        <Grid item xs={6} sm={6} md={4}>
          <BasicDropdown
            // key={'operationType'}
            key={`operation-type-${formKey}`}
            // label="Sell"
            placeholder='Transaction type'
            id='operationType'
            options={[
              {
                'label': "All",
                "id": 'all',
                "value": 'all',
              }, {
                'label': "Sell",
                "id": 'sell',
                "value": 'sell',
              },
              {
                'label': "Rent",
                "id": 'rent',
                "value": 'rent',
              },

            ]}
            onChange={(value) => setFormValue('operationType', value === 'all' ? '' : value)}
            value={filter?.operationType}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <BasicDropdown
            // key={'completionStatus'}
            // label="Completion status"
            key={`completion-type-${formKey}`}
            placeholder='Completion status'
            id='completionStatus'
            options={[
              {
                'label': "All",
                "id": 'all',
                "value": 'all',
              },
              {
                'label': "Completed",
                "id": 'completed',
                "value": 'completed',
              },
              {
                'label': "In Progress",
                "id": 'inprogress',
                "value": 'inprogress',
              },

            ]}
            onChange={(value) => setFormValue('completionStatus', value === 'all' ? '' : value)}
            value={filter?.completionStatus}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <BasicDropdown
            // key={'category'}
            key={`prop-type-${formKey}`}
            // label="Property Type"
            placeholder='Property Type'
            id='propertyType'
            options={PropertyCategories}
            onChange={(value) => setFormValue('category', value)}
            value={filter?.category}
          />
        </Grid>

        <Grid className='pc-options' item xs={12} sm={3} md={2}>
          <TextField
            id="minArea"
            name="minArea"
            placeholder="Min. Area"
            type="number"
            fullWidth
            value={filter.minArea}
            onChange={(e) => setFormValue('minArea', e.target.value)}
          />
        </Grid>
        <Grid className='pc-options' item xs={12} sm={3} md={2}>
          <TextField
            id="maxArea"
            name="maxArea"
            placeholder="Max. Area"
            type="number"
            fullWidth
            value={filter.maxArea}
            onChange={(e) => setFormValue('maxArea', e.target.value)}
          />
        </Grid>
        <Grid className='pc-options' item xs={12} sm={3} md={2}>
          <TextField
            id="minBedrooms"
            name="minBedrooms"
            placeholder="Min. Bedrooms"
            type="number"
            fullWidth
            value={filter.minBedrooms}
            onChange={(e) => setFormValue('minBedrooms', e.target.value)}
          />
        </Grid>
        <Grid className='pc-options' item xs={12} sm={3} md={2}>
          <TextField
            id="maxBedrooms"
            name="maxBedrooms"
            placeholder="Max. Bedrooms"
            type="number"
            fullWidth
            value={filter.maxBedrooms}
            onChange={(e) => setFormValue('maxBedrooms', e.target.value)}
          />
        </Grid>
        <Grid className='pc-options' item xs={12} sm={3} md={2}>
          <TextField
            id="minPrice"
            name="minPrice"
            placeholder="Min. Price"
            type="number"
            fullWidth
            value={filter.minPrice}
            onChange={(e) => setFormValue('minPrice', e.target.value)}
          />
        </Grid>
        <Grid className='pc-options' item xs={12} sm={3} md={2}>
          <TextField
            id="maxPrice"
            name="maxPrice"
            placeholder="Max. Price"
            type="number"
            fullWidth
            value={filter.maxPrice}
            onChange={(e) => setFormValue('maxPrice', e.target.value)}
          />
        </Grid>

        <Grid className='pc-options' item xs={12} sm={12} md={12}>
          <span className='slider-title'>Distance: {filter?.distance && <>{filter?.distance} km</>}</span>
          <Slider
            style={{ marginTop: '0px', height: '5px', padding: '10px 0' }}
            size="small"
            className='slider-bar'
            // marks={marks}
            // min={0}
            // step={0.05}
            // max={2}
            value={filter?.distance || 1}
            valueLabelDisplay="auto"
            onChange={(_, v) => setFormValue('distance', v)}
            sx={{
              '& .MuiSlider-thumb': {
                backgroundColor: 'var(--Text)', // Custom color for the thumb
              },
              '& .MuiSlider-track': {
                backgroundColor: 'var(--Text)', // Custom color for the track
              },
              '& .MuiSlider-rail': {
                backgroundColor: 'var(--Text)', // Custom color for the rail
              },
            }}
          />
        </Grid>


        <Grid className='mob-filter-box' item xs={6} sm={6} md={4}>
          <div className='flex-box'>
            <Button onClick={handleOpenPPForm} className='filter-popup-btn'><SVGIcons.FilterIcon /> </Button>
            <Button onClick={onSearch} className='search-btn'><SVGIcons.SearchIcon /> Search</Button>
          </div>

        </Grid>
      </Grid>
      <Modal
        className='filter-popup'
        open={openPPForm}
        onClose={handleClosePPForm}
        aria-labelledby="filter-popup"
        aria-describedby="filter-popup"
      >
        <div className='filter-option-form f-wrp'>
          <div className='filter-option-head'>
            <Button onClick={handleClosePPForm}>{SVGIcons.CrossIcon()}</Button>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                id="minArea"
                name="minArea"
                placeholder="Min. Area"
                type="number"
                fullWidth
                value={filter.minArea}
                onChange={(e) => setFormValue('minArea', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                id="maxArea"
                name="maxArea"
                placeholder="Max. Area"
                type="number"
                fullWidth
                value={filter.maxArea}
                onChange={(e) => setFormValue('maxArea', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                id="minBedrooms"
                name="minBedrooms"
                placeholder="Min. Bedrooms"
                type="number"
                fullWidth
                value={filter.minBedrooms}
                onChange={(e) => setFormValue('minBedrooms', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                id="maxBedrooms"
                name="maxBedrooms"
                placeholder="Max. Bedrooms"
                type="number"
                fullWidth
                value={filter.maxBedrooms}
                onChange={(e) => setFormValue('maxBedrooms', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                id="minPrice"
                name="minPrice"
                placeholder="Min. Price"
                type="number"
                fullWidth
                value={filter.minPrice}
                onChange={(e) => setFormValue('minPrice', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                id="maxPrice"
                name="maxPrice"
                placeholder="Max. Price"
                type="number"
                fullWidth
                value={filter.maxPrice}
                onChange={(e) => setFormValue('maxPrice', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <span className='slider-title'>Distance: {filter?.distance && <>{filter?.distance} km</>}</span>
              <Slider
                style={{ marginTop: '0px', height: '5px', padding: '10px 0' }}
                size="small"
                className='slider-bar'
                defaultValue={filter?.distance || 1}
                valueLabelDisplay="auto"
                onChange={(_, v) => setFormValue('distance', v)}
                sx={{
                  '& .MuiSlider-thumb': {
                    backgroundColor: 'var(--Text)', // Custom color for the thumb
                  },
                  '& .MuiSlider-track': {
                    backgroundColor: 'var(--Text)', // Custom color for the track
                  },
                  '& .MuiSlider-rail': {
                    backgroundColor: 'var(--Text)', // Custom color for the rail
                  },
                }}
              />
            </Grid>
          </Grid>
          <Button onClick={onSearch} className='search-btn'><SVGIcons.SearchIcon /> Search</Button>
        </div>
      </Modal>
    </form>
  );
}

export default FilterForm