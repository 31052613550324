import React from 'react';
import { Stack, Typography } from '@mui/material';
import ForgotPasswordForm from '../../../forms/forgotPassword.form';



const ForgotPasswordPage = () => {
    // const pageName = 'Forgot password';
    return (
      
            <div className='auth-page-layout auth-single-pg f-wrp'>
                <div className='container'>
                    <div className='flex-box'>
                        <div className='form-sec-wrp'>
                            {/* <Link to={'/'} className='auth-logo'><img src={AppIcons.logo} alt='register' /></Link> */}
                            <div className='auth-form'>
                                <Stack className='form-tile'>
                                    <Typography variant="h3" fontStyle={'normal'} fontWeight={'400'}>Forgot password</Typography>
                                    <Typography variant="h4" fontStyle={'normal'} fontWeight={'400'}>You will receive instructions to resetting your password</Typography>
                                </Stack>
                                <ForgotPasswordForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      
    )
}

export default ForgotPasswordPage;