import * as React from 'react';
import Button from '@mui/material/Button';
import TextInput from '../Components/inputs/textInput/textInput';
import { Stack } from '@mui/material';


function VerificationForm(props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({});

  /**
 * sets the form data 
 * @param {string} name the name of the field
 * @param {string} value  the value of the field
 */
  const setFormValue = (name, value) => {
    const formDataAs = { ...formData };
    setFormData({ ...formDataAs, ...{ [name]: value } });
  }

  /**
   * Validates the form and returns true if valid
   * @returns boolean
   */
  const isValidForm = () => {
    let isValid = false;
    if (formData && formData.name &&
      formData.email &&
      formData.query) {
      isValid = true;
    }
    return isValid;
  }

  /**
   * On submit the form
   */
  const onSubmit = () => {
    if (isValidForm()) {
      // Submitting data to backend for registration
      setIsLoading(true);

      const formPostData = {
        name: formData.name,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        query: formData.query
      }

    }
  };


  return (   
    <>
      <Stack className='login-form standard-form' spacing={3}>
        <TextInput
          key={`yourCode`}
          label='Your Code'
          name='yourCode'
          type='text'
          required
          placeholder='Please enter your code'
          className='custom-textfield'
          onChange={(e) => setFormValue('yourCode', e.target.value)}
          size='small'

        />
        <Button
          loading={isLoading}
          loadingPosition='start'
          disabled={!formData}
          className='auth-btn'
          onClick={onSubmit}>
          Send
        </Button>
      </Stack>
    </>
  );
}

export default VerificationForm