import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Button, Stack } from '@mui/material';
// components
import { forgotPassword } from '../services/api/auth';
import TextInput from '../Components/inputs/textInput/textInput';

// ----------------------------------------------------------------------

export default function ForgotPasswordForm() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = () => {
        setIsLoading(true);
        forgotPassword({ email }).then((_) => {
            navigate('/reset-password-email-sent', { replace: true });            
        }).finally(() => setIsLoading(false));
    };

    return (
        <>
            <Stack className='login-form standard-form' spacing={3}>
                <TextInput
                    key={`login-email`}
                    label='Email'
                    name='email'
                    type='email'
                    required
                    placeholder='Please enter your email address'
                    className='custom-textfield'
                    getValue={(_, value) => setEmail(value)}
                    size='small'

                />
                <Button
                    loading={isLoading}
                    loadingPosition='start'
                    disabled={!email}
                    className='auth-btn'
                    onClick={onSubmit}>
                    Send
                </Button>
            </Stack>

           


        </>
    );
}
