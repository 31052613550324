
/**
* @author Geethananth M
 * Use this file to define app related constants
 */


const CONSTANTS = {
    EMAIL_REGEX : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    VALID_PASSWORD_RULE: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    
}

/**
 * Application user roles
 */
export const USER_ROLES = {
    ADMIN: 'admin',
    EXTERNAL_AGENT: 'agent',
    USER: 'user',
}

/**
 * Application user status
 */
export const USER_STATUS = {
    active: 'active',
    inactive: 'inactive',
    suspended: 'suspended',
    banned: 'banned'
}



export default CONSTANTS;