import React from "react";
import AppIcons from "../../../assets/images/icons";
// import TeamLogo from "../../../assets/images/icons/teamLogo.svg";

import "./terms.scss"
import { ScrollToTopOnMount } from "../../../Components/ScrollToTop/ScrollToTop";
// import ContactPopup from "../../../Components/ContactPopup/ContactPopup";
import { Link } from "react-router-dom";



const PrivacyPolicy = () => {
    ScrollToTopOnMount();


    return (
        <div className="page-main-wrapper privacy-main-wrapper f-wrp">
            <div className="container">
                <div className="abt-img-wrp f-wrp">
                    <span><img src={AppIcons.AbtImg} alt="propshop-logo" /></span>
                </div>
                <div className="abt-cont-wrp f-wrp">
                    <h1 className="abt-main-header">Privacy Policy</h1>
                    <p>Last Updated: 12-march-2025</p><br />
                    <p>This Privacy Policy describes how Propshop Real Estate LLC ("we," "us," or "our") collects, uses, and shares information about you when you use our website www.propshop.ai (the "Site"). We are committed to protecting your privacy and ensuring the security of your personal information.</p>
                    <br></br>
                    <label>1. Information We Collect</label>
                    <p>We may collect the following types of information: </p>
                    <br></br>
                    <ul>
                        <li><b>Information You Provide Directly:</b></li>
                        <ul>
                            <li>Contact information: Name, email address, phone number, and postal address.</li>
                            <li>Property search criteria: Desired location, price range, property type, and other preferences.</li>
                            <li>Financial information: (If applicable) Information related to mortgage pre-approval or financing applications.</li>
                            <li>Information provided when scheduling showings or open house visits.</li>
                            <li>Any information you provide when contacting us through forms, email, or phone.</li>
                        </ul>
                        <br />
                        <li><b>Information Collected Automatically:</b></li>
                        <ul>
                            <li>Log data: IP address, browser type, operating system, referring URLs, and pages viewed.</li>
                            <li>Cookies and similar technologies: Information about your browsing activities on our Site.</li>
                            <li>Location data: (If applicable) Information about your location when using our property search features.</li>
                            <li>Analytics data: Information about how you interact with our website.</li>
                        </ul>
                    </ul>

                    <br></br>
                    <label>2. How We Use Your Information</label>
                    <p>We may use your information for the following purposes:</p>
                    <br></br>
                    <ul>
                        <li>To provide and maintain the Site and its features.</li>
                        <li>To match you with properties that meet your search criteria.</li>
                        <li>To facilitate communication between you and our real estate agents.</li>
                        <li>To schedule property showings and open house visits.</li>
                        <li>To process mortgage pre-approval or financing applications (if applicable).</li>
                        <li>To provide market analysis and property reports.</li>
                        <li>To respond to your inquiries and provide customer support.</li>
                        <li>To send you updates, newsletters, and marketing materials (with your consent).</li>
                        <li>To analyze and improve the Site's performance and user experience.</li>
                        <li>To comply with legal and regulatory obligations.</li>
                    </ul>
                    <br></br>
                    <label>3. Sharing Your Information</label>
                    <p>We may share your information with:</p>
                    <br></br>
                    <ul>
                        <li>Real Estate Agents and Brokers: To facilitate property transactions and communication.</li>
                        <li>Multiple Listing Services (MLS): To access and display property listings.</li>
                        <li>Third-Party Service Providers: Including website hosting, payment processors, analytics providers, and marketing platforms.</li>
                        <li>Title Companies, Escrow Services, Appraisers, and Inspectors: As necessary to facilitate real estate transactions.</li>
                        <li>Lead Generation Partners: with explicit consent.</li>
                        <li>Legal Authorities: When required by law or to protect our rights.</li>
                        <li>Business Transfers: In the event of a merger, acquisition, or sale of assets. </li>
                    </ul>
                    <br></br>
                    <label>4. Your Choices</label>
                    <ul>
                        <li>Cookies: You can control cookies through your browser settings.</li>
                        <li>Marketing Communications: You can opt out of receiving marketing emails by following the unsubscribe instructions in the emails.</li>
                        <li>Access and Correction: You can request to access, correct, or delete your personal information by contacting us at <a style={{ color: 'var(--nav-text)' }} href="mailto:support@propshop.ai">support@propshop.ai</a>.</li>
                    </ul>
                    <br></br>
                    <label>5. Security</label>
                    <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is completely secure.</p>
                    <br></br>
                    <label>6. Children's Privacy</label>
                    <p>Our Site is not intended for children under the age of 18. We do not knowingly collect personal information from children under 18. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us. </p>
                    <br></br>
                    <label>7. Changes to This Privacy Policy</label>
                    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.  </p>
                    <br></br>
                    <label>8. Contact Us</label>
                    <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                    <br />
                    <p style={{ lineHeight: '1.6' }}>
                        Propshop Real Estate LLC,<br />
                        Office 1914-170,<br />
                        The Binary by Omniyat,<br />
                        Business Bay.<br />
                        <a style={{ color: 'var(--nav-text)' }} href="mailto:support@propshop.ai">support@propshop.ai</a>
                    </p>
                </div>
                {/* <br></br>
                <br></br>
                <br></br>
                <div className="abt-cont-wrp f-wrp">
                    <label>QUESTIONS?</label>
                    <div className="contact-block-wrp f-wrp">
                        <ContactPopup />
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default PrivacyPolicy;
