import React, { useRef } from 'react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Navigation, Autoplay, } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import SVGIcons from '../../../assets/images/icons/svgIcons';

import './reviewList.scss';
import AppIcons from '../../../assets/images/icons';
// import S3ImageViewerWithFallback from '../../awsS3ImageViewer/ImgViewerWithFallback';



function ReviewList() {



  // eslint-disable-next-line react/prop-types
  const CustomPrevButton = ({ onClick }) => (
    <button type='button' className="swiper-button-prev" onClick={onClick}>
      <SVGIcons.LeftArrowIcon />
    </button>
  );
  // eslint-disable-next-line react/prop-types
  const CustomNextButton = ({ onClick }) => (
    <button type='button' className="swiper-button-next" onClick={onClick}>
      <SVGIcons.RightArrowIcon />
    </button>
  );
  const swiperRef = useRef(null);


  const TestimonialsListing = [
    {
      // testimg: AppIcons.test1,
      testDescription: <p>My experience working with Propshop to purchase a new home was exceptional. They were professional, knowledgeable, and highly responsive to all of my needs. I highly recommend Propshop to anyone looking to buy or sell a home. They are a team of true professionals dedicated to providing exceptional service.</p>,
      name: 'Ali Siddiqui',

    },
    {
      // testimg: AppIcons.test2,
      testDescription: <p>Propshop's team provided excellent customer service, answering my questions and providing regular updates on my purchase. I felt like they were invested in helping me find the perfect investment.</p>,
      name: 'Latifa Al Dhaheri',

    },
    {
      // testimg: AppIcons.test3,
      testDescription: <p>Propshop impressed me with their attention to detail and understanding of my needs. They provided options that fit my criteria perfectly and their expertise made the buying process easy.</p>,
      name: 'Rashid Al Shamsi',

    },
    {
      // testimg: AppIcons.test4,
      testDescription: <p>Having conducted several transactions in real estate over the years, PropShop by far outperformed any other brokerage we have worked with in terms of efficiency and professionalism. It was a hassle-free experience.</p>,
      name: 'Christian Thordal',
      // place: <p>Investor from Kjøller</p>,
    },
    {
      testDescription: <p>I've been looking into buying property in Dubai for the past two years and have spoken with quite a few sales advisors from different companies. Lately, I’ve been in touch with Yassir about a new development. he’s sent me a lot of useful info, and we’ve had a couple of video calls, plus I met with him and one of his colleagues in person.</p>,
      name: 'Yassef Kassem',
    },
    {
      testDescription: <p>It was a great experience overall. The team was super professional, responsive, and efficient throughout the process. I especially want to thank Ms. Naya Marie, who handled everything for our apartment purchase. I really appreciate all the help!</p>,
      name: 'Анастасия Ивановна Соколова',
    },

  ];

  return (
    <>
      <div className="item-listing-wrp blogListing-wrp reviewListing-wrp f-wrp">
        <Swiper
          onSwiper={(swiper) => { swiperRef.current = swiper; }}
          modules={[Navigation, Autoplay]}
          spaceBetween={30}
          slidesPerView={2}
          className='property-slider blog-slider f-wrp'
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
              navigation: true,
              autoplay: true,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
              navigation: true,
              autoplay: true,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
              navigation: false,
              autoplay: true,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 20,
              navigation: false,
            },
            1275: {
              slidesPerView: 2,
              spaceBetween: 10,
              navigation: false,
              autoplay: false,
            },
          }}

        >
          {TestimonialsListing.map((element, key) => (
            <SwiperSlide key={key}>
              <div className="each-property-items each-reviews f-wrp">
                <div className='review-sec'>
                  <div className='review-head'>
                    <h2>5 <SVGIcons.StarIcon /> <SVGIcons.StarIcon /> <SVGIcons.StarIcon /> <SVGIcons.StarIcon /> <SVGIcons.StarIcon /></h2>
                    <p>3 weeks ago</p>
                  </div>
                  {element.testDescription}
                </div>
                <div className='review-foot'>
                  {element.name ? <p>{element.name}</p> : null}
                  <span><img src={AppIcons.google} alt='review' /></span>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <CustomPrevButton onClick={() => swiperRef.current.slidePrev()} />
        <CustomNextButton onClick={() => swiperRef.current.slideNext()} />
      </div>
    </>
  );
}

export default ReviewList;