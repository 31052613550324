const AppIcons = {
    sampleIcon: require('../icons/sample.ico'),
    slider1: require('./slider1.jpeg'),
    slider2: require('./slider2.png'),
    logo: require('./Logo.png'),
    logoSecondary: require('./logoSecondary.png'),
    burgericon: require('./solarHamburgerMenu.png'),
    banner: require('./main-banner.jpg'),
    home: require('./newHero.png'),
    matchMaking: require('./matchMaking.png'),
    list1: require('./list1.png'),
    list2: require('./list2.png'),
    list3: require('./list3.png'),
    list4: require('./list4.png'),
    list5: require('./list5.png'),
    list6: require('./list6.png'),
    test1: require('./test1.png'),
    test2: require('./test2.png'),
    test3: require('./test3.png'),
    test4: require('./test4.png'),
    footLogo: require('./footLogo.png'),
    foottag: require('./PROPSHOP.png'),
    facebook: require('./facebook.png'),
    insta: require('./instagram.png'),
    linkedin: require('./linkedin.png'),
    ticktok: require('./Ticktok.png'),
    snapchat: require('./snapchat.png'),

    prop1_01: require('./../icons/prop1/01.png'),
    prop1_02: require('./../icons/prop1/02.png'),
    prop1_03: require('./../icons/prop1/03.png'),
    prop1_04: require('./../icons/prop1/04.png'),
    prop1_05: require('./../icons/prop1/05.png'),
    prop1_06: require('./../icons/prop1/06.png'),
    prop1_07: require('./../icons/prop1/07.png'),
    prop1_08: require('./../icons/prop1/08.png'),

    prop2_01: require('./../icons/prop2/01.png'),
    
    prop3_01: require('./../icons/prop3/01.png'),
    prop3_02: require('./../icons/prop3/02.png'),

    prop4_01: require('./../icons/prop4/01.png'),
    prop4_02: require('./../icons/prop4/02.png'),
    prop4_03: require('./../icons/prop4/03.png'),
    prop4_04: require('./../icons/prop4/04.png'),
    prop4_05: require('./../icons/prop4/05.png'),
    prop4_06: require('./../icons/prop4/06.png'),
    prop4_07: require('./../icons/prop4/07.png'),
    prop4_08: require('./../icons/prop4/08.png'),
    prop4_09: require('./../icons/prop4/09.png'),
    prop4_10: require('./../icons/prop4/10.png'),
    prop4_11: require('./../icons/prop4/11.png'),

    prop5_01: require('./../icons/prop5/01.png'),
    prop5_02: require('./../icons/prop5/02.png'),
    prop5_03: require('./../icons/prop5/03.png'),
    prop5_04: require('./../icons/prop5/04.png'),
    prop5_05: require('./../icons/prop5/05.png'),
    prop5_06: require('./../icons/prop5/06.png'),
    prop5_07: require('./../icons/prop5/07.png'),
    prop5_08: require('./../icons/prop5/08.png'),

    prop6_01: require('./../icons/prop6/01.png'),
    prop6_02: require('./../icons/prop6/02.png'),
    prop6_03: require('./../icons/prop6/03.png'),
    prop6_04: require('./../icons/prop6/04.png'),
    prop6_05: require('./../icons/prop6/05.png'),
    prop6_06: require('./../icons/prop6/06.png'),
    prop6_07: require('./../icons/prop6/07.png'),
    prop6_08: require('./../icons/prop6/08.png'),
    prop6_09: require('./../icons/prop6/09.png'),
    prop6_10: require('./../icons/prop6/10.png'),
    prop6_11: require('./../icons/prop6/11.png'),
    prop6_12: require('./../icons/prop6/12.png'),
    prop6_13: require('./../icons/prop6/13.png'),

    AbtImg: require('./abtImg.png'),
    MobBannerLogo: require('./MobBannerLogo.png'),
    MobPROPSHOP: require('./MobPROPSHOP.png'),

    team1: require('./../icons/team/team1.png'),
    team2: require('./../icons/team/team2.png'),
    team3: require('./../icons/team/team3.png'),
    team4: require('./../icons/team/team4.png'),
    team5: require('./../icons/team/team5.png'),
    team6: require('./../icons/team/team6.png'),
    team7: require('./../icons/team/team7.png'),
    team8: require('./../icons/team/team8.png'),
    
    sampleProfile: require('./sampleProfile.png'),
    agentDefault: require('./default.jpg'),
    user: require('./user.png'),
    partnerDefault: require('./partnerDefault.png'),
    ContactImg: require('./contactImg.png'),
    map: require('./map.png'),
    
    partner1: require('./../icons/partners/partner1.png'),
    partner2: require('./../icons/partners/partner2.png'),
    partner3: require('./../icons/partners/partner3.png'),
    partner4: require('./../icons/partners/partner4.png'),
    partner5: require('./../icons/partners/partner5.png'),
    
    developer1: require('./../icons/developer/emaar.png'),
    developer2: require('./../icons/developer/Damac.png'),
    developer3: require('./../icons/developer/sobha.png'),
    developer4: require('./../icons/developer/nakheel.png'),
    developer5: require('./../icons/developer/Ellington.png'),
    developer6: require('./../icons/developer/majid.png'),

    service1: require('./../icons/service/service1.png'),
    service2: require('./../icons/service/service2.png'),
    service3: require('./../icons/service/service3.png'),
    service4: require('./../icons/service/service4.png'),

    honeyComb: require('./../icons/honeyComb.png'),
    forInvesters: require('./../icons/forInvesters.png'),
    honeylogo: require('./../icons/honeylogo.png'),
    bgBlk: require('./../icons/bgBlk.png'),

    chevronLeft: require('./../chevronLeft.png'),
    chevronRight: require('./../chevronRight.png'),

    invest: require('./../icons/invest.png'),
    signUp: require('./../icons/signUp.png'),
    check: require('./../icons/check.png'),
    choose: require('./../icons/choose.png'),

    fractionalBg1: require('./../icons/fractionalBg1.png'), 
    fractionalBg2: require('./../icons/fractionalBg2.png'),

    traditionalBG: require('./../icons/traditionalBG.png'),
    
    
    homeLogo: require('./../HomePage/logo.png'),
    homeBannerBG: require('./../HomePage/bannerBG.png'),
    serviceBG: require('./../HomePage/serviceBG.png'),
    google: require('./../HomePage/google.png'),

    Sarah_Sajwani: require('./../HomePage/our-team/Sarah_Sajwani.png'),
    Muhd_Waqas: require('./../HomePage/our-team/Muhd_Waqas.png'),
    Muhd_Yassir: require('./../HomePage/our-team/Muhd_Yassir.png'),
    Ahmad_Bin_Fadeal: require('./../HomePage/our-team/Ahmad_Bin_Fadeal.png'),
    Larisa_Bekasova: require('./../HomePage/our-team/Larisa_Bekasova.png'),
    santiago_carnicero: require('./../HomePage/our-team/santiago_carnicero.png'),
        
    Sarah: require('./../HomePage/sarah-img.png'),
    footerLogo: require('./../HomePage/propshop.png'),
    


    


}

export default AppIcons;
