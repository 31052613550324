import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './Components/LandingPage';
import AboutUs from './pages/public/About';
import Listing from './pages/public/Listing';

import SamplePage from './Features/SamplePage';
import Layout from './Layout';
import PropertyDetails from './pages/public/Listing/PropertyDetails';
import MatchMaking from './pages/public/MatchMaking';
// import Home from './Components/Main/home';
import FractionalPage from './Components/FractionalPage';
import TraditionalPage from './Components/TraditionalPage';
import Blogs from './pages/public/Blogs';
import IndividualBlog from './pages/public/Blogs/individualBlog';
import RegisterPage from './pages/public/auth/register';
import LoginPage from './pages/public/auth/login';
import VerificationPage from './pages/public/auth/verification';
import ForgotPasswordPage from './pages/public/auth/forgotPassword';
import PasswordResetMailSuccess from './pages/public/auth/passwordResetMailSuccess';
import HomeRevamp from './Components/homeRevamp';
import PrivacyPolicy from './pages/public/termsAndPrivacy/privacyPolicy';
import TermsAndConditions from './pages/public/termsAndPrivacy/termsAndConditions';


const AppRoutes = () => {
    return (
        <Layout>
            <Routes>
                <Route path="/home-old" key={'home'} isHome={true} element={<LandingPage isHome={true} />} />
                {/* <Route path="/home" element={<Home />} /> */}
                <Route path="/fractional" element={<FractionalPage />} />
                <Route path="/traditional" element={<TraditionalPage />} />
                <Route path="/" element={<HomeRevamp />} />


                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/exclusive-listings" element={<Listing />} />
                <Route path="/property-detail/:id" element={<PropertyDetails />} />
                <Route path="/match-making" element={<MatchMaking />} />
                <Route path="/blog" element={<Blogs />} />
                <Route path="/blog-details/:id" element={<IndividualBlog />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />


                {/* authentication routes */}
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/verification" element={<VerificationPage />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/reset-password-email-sent" element={<PasswordResetMailSuccess />} />





                <Route path="/contact-us" element={<SamplePage />} />
            </Routes>
        </Layout>
    )
}

export default AppRoutes;