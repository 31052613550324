import { React, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppIcons from '../../../assets/images/icons';
import BackArrow from '../../../assets/images/icons/BackArrow.svg';
import ArrowsOutBlk from '../../../assets/images/icons/ArrowsOutBlk.svg';
import focusBlk from '../../../assets/images/icons/focusBlk.svg';
// import discountBlk from '../../../assets/images/icons/discountBlk.svg';
import whatsappIcon from '../../../assets/images/icons/whatsappIcon.svg';

// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';

// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";

import "./listing.scss"
import { ScrollToTopOnMount } from "../../../Components/ScrollToTop/ScrollToTop";
import SliderThumbPage from "./SliderThumbPage";
import { showSnackbar } from "../../../Components/Notification/Snackbar";
import { getProperty } from "../../../services/api/property";
import { PropertyModel } from "../../../models/property.model";
import RichTextViewer from "../../../Components/RichText/RichTextViewer";
import SVGIcons from "../../../assets/images/icons/svgIcons";
import ContactPopup from "../../../Components/ContactPopup/ContactPopup";
import MapComponent from "../../../Components/MapSection";
import { Helmet } from "react-helmet-async";




const PropertyDetails = () => {

  ScrollToTopOnMount();
  const params = useParams();
  const navigate = useNavigate();
  const propId = params?.id || '';
  const propertyId = propId;
  const [property, setProperty] = useState(PropertyModel);


  console.log(propertyId, 'propertyId');

  ScrollToTopOnMount();

  /**
  the legendary use effect function
  This will run very first on render
   */
  useEffect(() => {
    if (propertyId) {
      getPropertyAs()
    }
    return () => {
      setProperty(null)
    };
    // eslint-disable-next-line
  }, []);

  // get users from api
  const getPropertyAs = () => {
    getProperty(propertyId).then((data) => setProperty(data)).catch(() => showSnackbar().failure('Something went wrong'))

  }

  return (
    <>
      {property?.title ? <Helmet>
        <title>{property?.title}</title>
        <link rel={property?.title} href="#" />

        {property?.title && <meta property="og:title" content={property?.title} />}
        {property?.metaTags && <meta name="keywords" content={property?.metaTags} />}
        {property?.metaDescription && <meta name="description" content={property?.metaDescription} />}
        {property?.metaDescription && <meta property="og:description" content={property?.metaDescription} />}

      </Helmet> : null}
      <div id='app-main-content' className="main-content-wrapper listing-wrapper listing-detail-wrp f-wrp">
        <div className="container">
          <div className="breadcrumb f-wrp">
            <Link to={'#'} onClick={() => navigate(-1)}><img src={BackArrow} alt="back-arrow" />All listings</Link>
            <h1 className="prop-label">{property?.title}</h1>
          </div>

          <div className="f-wrp sdadfds">
          </div>
          <div className="detail-slider-wrp f-wrp">
            {/* <Carousel
            className="carousel-flex"
            axis="horizontal"
            verticalSwipe="natural"
            autoFocus
            autoPlay
            centerMode
            Thumb
            centerSlidePercentage={100}
            emulateTouch
            infiniteLoop
            thumbWidth={200}
            showThumbs={true}

          >
            <div>
              <img src={AppIcons.prop1_01} alt="property" />
            </div>
            <div>
              <img src={AppIcons.prop1_02} alt="property" />
            </div>
            <div>
              <img src={AppIcons.prop1_03} alt="property" />
            </div>

          </Carousel> */}

            {property?.images?.length ? <SliderThumbPage images={property?.images ? property?.images.map(img => img.tempURL) : []}
              thumbnails={(property?.thumbnails > 0) ? property?.thumbnails.filter((i) => i && i.tempURL).map(img => img.tempURL) : property?.images.filter((i) => i && i.tempURL).map(img => img.tempURL)} /> : null}
          </div>
          <br></br>

          <div className="row mobRow" style={{ marginLeft: '-10px', width: 'calc(100% + 10px)' }}>
            <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12" style={{ paddingLeft: '0', paddingRight: '8px' }}>

              <div className="property-detils each-block-sec f-wrp">
                <div className="prop-detail-wrp f-wrp">
                  {property?.price && <div className="mob-price"><b><i>AED</i> {property?.price}</b></div>}
                  <ul className="flex-list f-wrp">
                    {/* {property?.plot && <li>
                    <p><span className="list-icon"><img src={ArrowsOutBlk} alt="arrowout-icon" /></span> Plot: <b>{property?.plot} sft</b></p>
                  </li>} */}
                    {property?.area &&
                      <li>
                        <p><span className="list-icon"><img src={ArrowsOutBlk} alt="arrowout-icon" /></span> Area: <b>{property?.area} {property?.areaMeasureUnit}</b></p>
                      </li>}
                    {property?.bua &&
                      <li>
                        <p><span className="list-icon"><img src={focusBlk} alt="focus-icon" /></span> BUA: <b>{property?.bua} sft</b></p>
                      </li>}

                    {property?.noOfBedrooms &&
                      <li>
                        <p><span className="list-icon"><SVGIcons.BedIcon /></span> Beds: <b>{property?.noOfBedrooms}</b></p>
                      </li>}
                    {property?.noOfBathrooms &&
                      <li>
                        <p><span className="list-icon"><SVGIcons.BathIcon /></span> Baths: <b>{property?.noOfBathrooms}</b></p>
                      </li>}
                    {/* <li>
                <span className="list-icon"><img src={discountBlk} alt="discount-icon" /></span>
                <p>Commision: <b>2%</b></p>
              </li> */}
                  </ul>
                </div>
                <div className="agent-detail-wrp f-wrp">
                  <ul>
                    <li>
                      <div className="profile-blk">
                        <h4>{property?.agentName}</h4>
                        <span><img src={AppIcons.agentDefault} alt="agent" /></span>
                      </div>
                    </li>
                    <li>
                      <div className="contct-btn-sec f-wrp">
                        <span className="whstapp-btn" onClick={() => window.open('https://wa.me/971508880091', '_blank')} ><img src={whatsappIcon} alt="whatsapp" />Contact agent</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {(property?.description) ?
                <div className="property-description-blk feature-list each-block-sec f-wrp">
                  <h4>Description:</h4>
                  {(property?.description) && <RichTextViewer richText={property?.description} />}
                </div> : null}
              {(property?.features) ?
                <div className="property-feature-blk feature-list each-block-sec f-wrp">
                  <h4>Features:</h4>
                  {(property?.features) && <RichTextViewer richText={property?.features} />}
                </div> : null}
              {property?.amenities ?
                <div className="property-location-blk feature-list each-block-sec f-wrp">
                  <h4>Amenities:</h4>
                  <ul className="flex-list property-amenities-lists f-wrp" style={{ paddingTop: '0', paddingLeft: '15px' }}>
                    {property?.amenities?.barbequeArea && <li><p><b>Barbeque area</b></p></li>}
                    {property?.amenities?.lawnOrGarden && <li><p><b>Lawn or garden</b></p></li>}
                    {property?.amenities?.cafeteria && <li><p><b>Cafeteria</b></p></li>}
                    {property?.amenities?.daycare && <li><p><b>Daycare</b></p></li>}
                    {property?.amenities?.kidsPlayArea && <li><p><b>Kids play area</b></p></li>}
                    {property?.amenities?.sundeck && <li><p><b>Sundeck</b></p></li>}
                    {property?.amenities?.laundryRoom && <li><p><b>Laundry room</b></p></li>}
                    {property?.amenities?.laundryFacility && <li><p><b>Laundry facility</b></p></li>}
                    {property?.amenities?.sharedKitchen && <li><p><b>Shared kitchen</b></p></li>}
                    {property?.amenities?.medicalCenter && <li><p><b>Medical center</b></p></li>}
                    {property?.amenities?.sauna && <li><p><b>Sauna</b></p></li>}
                    {property?.amenities?.jacuzzi && <li><p><b>Jacuzzi</b></p></li>}
                    {property?.amenities?.disabledFriendly && <li><p><b>Disabled friendly</b></p></li>}
                    {property?.amenities?.gym && <li><p><b>Gym</b></p></li>}
                    {property?.amenities?.healthClub && <li><p><b>Health club</b></p></li>}
                    {property?.amenities?.swimmingPool && <li><p><b>Swimming pool</b></p></li>}
                    {property?.amenities?.steamRoom && <li><p><b>Steam room</b></p></li>}
                    {property?.amenities?.balcony && <li><p><b>Balcony</b></p></li>}
                    {property?.amenities?.terrace && <li><p><b>Terrace</b></p></li>}
                    {property?.amenities?.lobby && <li><p><b>Lobby</b></p></li>}
                    {property?.amenities?.serviceElevators && <li><p><b>Service elevators</b></p></li>}
                    {property?.amenities?.prayerRoom && <li><p><b>Prayer room</b></p></li>}
                    {property?.amenities?.reception && <li><p><b>Reception</b></p></li>}
                    {property?.amenities?.atmFacility && <li><p><b>ATM facility</b></p></li>}
                    {property?.amenities?.concierge24h && <li><p><b>24h concierge</b></p></li>}
                    {property?.amenities?.maidsRoom && <li><p><b>Maids room</b></p></li>}
                    {property?.amenities?.bussinessCenter && <li><p><b>Bussiness center</b></p></li>}
                    {property?.amenities?.conferenceRoom && <li><p><b>Conference room</b></p></li>}
                    {property?.amenities?.securityStaff && <li><p><b>Security staff</b></p></li>}
                    {property?.amenities?.cctvSecurity && <li><p><b>CCTV security</b></p></li>}
                    {property?.amenities?.doubleGlazedWindows && <li><p><b>Double glazed windows</b></p></li>}
                    {property?.amenities?.centralyAirConditioned && <li><p><b>Centraly air-conditioned</b></p></li>}
                    {property?.amenities?.centalHeating && <li><p><b>Cental heating</b></p></li>}
                    {property?.amenities?.electricityBackup && <li><p><b>Electricity backup</b></p></li>}
                    {property?.amenities?.studyRoom && <li><p><b>Study room</b></p></li>}
                    {property?.amenities?.storageAreas && <li><p><b>Storage areas</b></p></li>}
                    {property?.amenities?.broadbandInternet && <li><p><b>Broadband internet</b></p></li>}
                    {property?.amenities?.sateliteCableTV && <li><p><b>Satelite/Cable TV</b></p></li>}
                    {property?.amenities?.intercom && <li><p><b>Intercom</b></p></li>}
                    {property?.amenities?.cleaningservices && <li><p><b>Cleaning services</b></p></li>}
                    {property?.amenities?.maintenanceStaff && <li><p><b>Maintenance staff</b></p></li>}
                    {property?.amenities?.wasteDisposal && <li><p><b>Waste disposal</b></p></li>}
                  </ul>
                </div> : null}
              <div className="property-location-blk feature-list each-block-sec f-wrp">
                <h4>Location:</h4>
                <span>{property?.location}</span>
                <br></br>
                <br></br>
                <div className="map-wrp">
                  {(property?.latitude && property?.longitude) ?
                    <MapComponent
                      showMap
                      showSearchInput={false}
                      initialData={{
                        latitude: property?.latitude,
                        longitude: property?.longitude,
                        location: property?.location

                      }} />
                    : null}
                </div>
              </div>
              {property?.category || property?.subcategory || property?.completionStatus || property?.operationType ? <div className="property-location-blk feature-list each-block-sec f-wrp" style={{ marginBottom: '0' }}>
                <h4>Property details:</h4>
                <ul className="flex-list property-detail-list f-wrp" style={{ paddingTop: '0' }}>
                  {property?.category && <li>
                    <p>Category: <b>{property?.category}</b></p>
                  </li>}
                  {property?.subcategory && <li>
                    <p>Subcategory: <b>{property?.subcategory}</b></p>
                  </li>}
                  {property?.completionStatus &&
                    <li>
                      <p>Completion status: <b>{property?.completionStatus}</b></p>
                    </li>}
                  {property?.operationType &&
                    <li>
                      <p>Purpose: <b>{property?.operationType}</b></p>
                    </li>}
                </ul>
              </div> : null}



            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12" style={{ paddingLeft: '4px', paddingRight: '0' }}>
              <div className="sticky-contact-box">
                <span>CURRENT price</span>
                {property?.price && <div className="price">AED&nbsp;<b>{property?.price}</b></div>}
                <h4>Interested?</h4>
                <p>Let me know and I'll get in touch with you to discuss this property.</p>
                <div className="contct-btn-sec f-wrp">
                  <span className="whstapp-btn" onClick={() => window.open('https://wa.me/971508880091', '_blank')} ><img src={whatsappIcon} alt="whatsapp" />Contact agent</span>
                </div>
                <div className="contct-btn-sec inquiry f-wrp">
                  <ContactPopup />
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </>
  );
};

export default PropertyDetails;
