import { useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// @mui
import { Button, FormControl, FormLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// components
import { ScrollToTopOnMount } from "../Components/ScrollToTop/ScrollToTop";
import { register } from '../services/api/auth';
import { showSnackbar } from '../Components/Notification/Snackbar';
import TextInput from '../Components/inputs/textInput/textInput';
import { isLoggedIn } from '../helpers/auth.helper';
import { authState } from '../state/auth.state';
import { USER_ROLES } from '../constant';
import { PasswordRequirementCheckBoxes } from '../Components/inputs/textInput/password.requirements';





// ----------------------------------------------------------------------

export default function RegisterForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const isAuth = isLoggedIn();
    const auth = useAtomValue(authState);
    const setAuth = useSetAtom(authState);
    const { from } = location.state || { from: { pathname: `/` } };
    ScrollToTopOnMount();
    if (isAuth) {
        navigate(from, { replace: true });
    }
    // state variables for handling input fields values
    const [isLoading, setIsLoading] = useState(false);
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        userRole: auth.userRole,
        isTermsChecked: '',
        password: ''
    })
    const [validPassword, setValidPassword] = useState({
        eightCharacter: false,
        containNumber: false,
        containUpperCase: false,
        containSpecialChar: false
    });
    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData({ ...formData, ...{ [name]: value } })

    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.userRole && formData.email && formData.password && formData.firstName && formData.lastName && isTermsChecked) {
            isValid = true;
        }
        return isValid;
    }


    const [validUserRole, setValidUserRole] = useState();
    const [validEmail, setValidEmail] = useState();
    const [validPasswordCheck, setValidPasswordCheck] = useState();
    const [validFirstName, setValidFirstName] = useState();
    const [validLastName, setValidLastName] = useState();
    const [validIsTermsChecked, setValidIsTermsChecked] = useState();

    const validateFields = () => {
        if (formData.userRole) {
            setValidUserRole(true);
        }
        else {
            setValidUserRole(false);
        };
        if (formData.email) {
            setValidEmail(true);
        }
        else {
            setValidEmail(false);
        };
        if (formData.password) {
            setValidPasswordCheck(true);
        }
        else {
            setValidPasswordCheck(false);
        };
        if (formData.firstName) {
            setValidFirstName(true);
        }
        else {
            setValidFirstName(false);
        };
        if (formData.lastName) {
            setValidLastName(true);
        }
        else {
            setValidLastName(false);
        };
        if (formData.isTermsChecked) {
            setValidIsTermsChecked(true);
        }
        else {
            setValidIsTermsChecked(false);
        };

    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsLoading(true);
            const postData = {
                "role": formData.userRole,
                "email": formData.email,
                "password": formData.password,
                "firstName": `${formData.firstName}`,
                "lastName": `${formData.lastName}`
            }
            register(postData).then((data) => {
                if (data.success) {
                    if(formData.userRole === USER_ROLES.EXTERNAL_AGENT){
                        showSnackbar().success('Once the admin has approved your request, you’ll be able to log in.')
                    } else{
                        setAuth({...data?.data, isLoggedIn: true});
                    }
                    navigate(from, { replace: true });
                    showSnackbar().success(data.message)
                } else {
                    showSnackbar().failure(data.message);
                }
            }).finally(() => setIsLoading(false));

        }

    };


    /**
    * To validate password requirement
    * @param {String} password 
    */
    const validatePassword = (password) => {
        const rules = {
            eightCharacter: false,
            containNumber: false,
            containUpperCase: false,
            containSpecialChar: false
        }

        if (password.length > 7) { // To check if password contain 8 chars
            rules.eightCharacter = true;
        }
        if (/\d/.test(password)) { // To check if password contain numbers
            rules.containNumber = true;
        }
        if (password !== password.toLowerCase()) { // To check if password contain uppercase
            rules.containUpperCase = true;
        }
        const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
        if (format.test(password)) { // To check if password contain special chars
            rules.containSpecialChar = true;
        }
        setValidPassword(rules)
        if (rules.containNumber &&
            rules.containSpecialChar &&
            rules.containUpperCase &&
            rules.eightCharacter
        ) {
            setFormValue('password', password)
        } else {
            setFormValue('password', '')
        }
    }



    return (
        <>

            <Stack className='login-form register-form standard-form' spacing={3}>

                <div className='register-radio-btn' style={{position: 'relative'}}>
                    <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">
                            <Typography variant="h3" fontStyle={'normal'} alignItems={'center'} fontWeight={'300'}>Register</Typography>
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={formData.userRole}
                            onChange={(e) => setFormValue('userRole', e.target.value)}
                        >
                            <FormControlLabel value={USER_ROLES.EXTERNAL_AGENT} control={<Radio />} label={'Agent/broker'} />
                            <FormControlLabel value={USER_ROLES.USER} control={<Radio />} label={'Client'} />
                        </RadioGroup>
                    </FormControl>
                    {validUserRole === false ? <p className='errText'>Required</p> : null}
                </div>


                <div className='row'>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12' style={{position: 'relative'}}>
                        <TextInput
                            key={`register_firstName`}
                            label='First name'
                            name='firstName'
                            type='text'
                            isRequired
                            placeholder='First name'
                            className='custom-textfield'
                            getValue={setFormValue}
                            size='small'

                        />
                        {validFirstName === false ? <p className='errText'>Required</p> : null}
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12' style={{position: 'relative'}}>
                        <TextInput
                            key={`register_lastName`}
                            label='Last name'
                            name='lastName'
                            type='text'
                            isRequired
                            placeholder='Last name'
                            className='custom-textfield'
                            getValue={setFormValue}
                            size='small'

                        />
                        {validLastName === false ? <p className='errText'>Required</p> : null}
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{position: 'relative'}}>
                        <TextInput
                            key={`register_email`}
                            label='Email'
                            name='email'
                            type='email'
                            isRequired
                            placeholder='Enter your email'
                            className='custom-textfield'
                            getValue={setFormValue}
                            size='small'

                        />
                        {validEmail === false ? <p className='errText'>Required</p> : null}
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{position: 'relative'}}>
                        <TextInput
                            key={`register-password`}
                            label='Password'
                            name='register_password'
                            type='password'
                            isRequired={false}
                            placeholder='Password'
                            className='custom-textfield'
                            getValue={(_, value) => validatePassword(value)}
                            size='small'

                        />
                        {validPasswordCheck === false ? <p className='errText'>Required</p> : null}
                    </div>

                </div>

                <PasswordRequirementCheckBoxes rules={validPassword} />
                <FormGroup className='custom-checkbox' style={{position: 'relative'}}>
                    <FormControlLabel control={<Checkbox checked={isTermsChecked} onChange={(e) => setIsTermsChecked(e.target.checked)} />} label={
                        <>I agree to the <Link to={`/terms-and-conditions`}>Terms of use</Link> and <Link to={`/privacy-policy`}>Privacy policy</Link></>
                    } />
                    {validIsTermsChecked === false ? <p className='errText'>Required</p> : null}
                </FormGroup>
                
                <div className='f-wrp'>
                    <Button className='auth-btn'
                        disabled={!isValidForm()}
                        onClick={onSubmit}>
                        {isLoading ? 'Loading..' : 'Sign up'}
                    </Button>
                    <button className='validateBtn' type='button' onClick={() => validateFields()}>validate</button>
                </div>

            </Stack>
            <Stack className='additional-btn' variant='div' spacing={3}>
                <p>Already have an account <Link to={`/login`}>Login</Link></p>
            </Stack>

        </>
    );
}
