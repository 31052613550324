import React from 'react';
import AppIcons from '../../../assets/images/icons/index';
import RegisterForm from '../../../forms/register.form';
import './auth.scss';

const RegisterPage = () => {


    return (
        <div className='login-wrp register-wrp auth-layout'>
            <div className='auth-page-layout f-wrp'>
                <div className='flex-box'>
                    <div className='form-sec-wrp'>
                        <div className='auth-form'>
                            <RegisterForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className='form-img-wrp'>
                <span><img src={AppIcons.banner} alt='register' /></span>
            </div>
        </div>
    )
}

export default RegisterPage;