import { React, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { Button } from '@mui/material';

import AppIcons from '../../../assets/images/icons';
import BackArrow from '../../../assets/images/icons/BackArrow.svg';
import MapPinBlk from '../../../assets/images/icons/MapPinBlk.svg';
import ArrowsOutBlk from '../../../assets/images/icons/ArrowsOutBlk.svg';
import focusBlk from '../../../assets/images/icons/focusBlk.svg';
// import discountBlk from '../../../assets/images/icons/discountBlk.svg';
// import whatsappIcon from '../../../assets/images/icons/whatsappIcon.svg';

import whatsap from '../../../assets/images/icons/whatsap.svg';
// import phone from '../../../assets/images/icons/call.svg';
// import mail from '../../../assets/images/icons/email.svg';


import "./listing.scss"
import { ScrollToTopOnMount } from "../../../Components/ScrollToTop/ScrollToTop";
import CallPopup from "../../../Components/ContactPopup/CallPopup";
import ContactPopup from "../../../Components/ContactPopup/ContactPopup";
import { getProperties } from "../../../services/api/property";
import { showSnackbar } from '../../../Components/Notification/Snackbar';
import RichTextViewer from "../../../Components/RichText/RichTextViewer";
import ListThumbSlider from "../../../Components/ListThumbSlider";

import { initialPropertyState, propertyState } from "../../../state/property.state";
import SVGIcons from "../../../assets/images/icons/svgIcons";
import PropertyListGridView from "./propertyListGridView";
import FilterForm from "../../../Components/homeRevamp/filterForm/filterForm";
import { getUniqueId } from "../../../helpers/helper.functions";


/**
 * @description - this is a listing page for all properties. It uses react-router
* to navigate between pages and also has some basic routing logic in place
 */
const Listing = () => {
  const params = new URLSearchParams(useLocation().search);
  const mode = params.get('mode');
  const [propertyListKey, SetPropertyListKey] = useState('property-list');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [propertyStateAtom, setPropertyStateAtom] = useAtom(propertyState);
  console.log(propertyStateAtom)
  ScrollToTopOnMount();

  /**
  the legendary use effect function
  This will run very first on render
   */
  useEffect(() => {
    getPropertiesAs(mode === 'filtered')
    return () => {
      setData([]);
      clearFilter();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get users from api
  const getPropertiesAs = (isShowFiltered = false) => {
    const filter = isShowFiltered ? { ...propertyStateAtom.filter, limit: 1000, page: 1 } : { limit: 1000, page: 1 };
    setIsLoading(true);
    getProperties(filter)
      .then(({ data }) => setData(data))
      .catch(() => showSnackbar().failure('Something went wrong'))
      .finally(() => setIsLoading(false))
  }
  const generateSlug = (title) => {
    return title
      .replace(/\s+/g, '_') // Replace spaces with dashes
  };

  // To set the view grid and list

  const [listView, setListView] = useState('grid');
  const handleGridView = () => setListView('grid');
  const handleListView = () => setListView('list');


  const clearFilter = () => {

    getPropertiesAs(false);
    // navigate(`/exclusive-listings`);
    SetPropertyListKey(`${getUniqueId()}-new-filter-list`);
    setPropertyStateAtom({ ...propertyStateAtom, filter: { ...initialPropertyState.filter } })
  }


  return (
    <div id='app-main-content' className="main-content-wrapper listing-wrapper f-wrp">
      <div className='main-banner-wrp inner-banner f-wrp'>
        <div className='banner-bg f-wrp'>
          <img src={AppIcons.banner} alt='buildings' />
        </div>
        <div className="container">
          <div className='filter-box f-wrp'>
            <div className='filter-head'>
              <h4>Find Your Property</h4>
              <Button onClick={() => getPropertiesAs(true)} className='search-btn'><SVGIcons.SearchIcon /> Search</Button>
            </div>
            <div className='filter-form'>
              <FilterForm formKey={propertyListKey} onFilter={() => getPropertiesAs(true)} />
            </div>
          </div>
        </div>
      </div>
      <div className="breadcrumb f-wrp">
        <div className="container">
          <Link to={"/"}><img src={BackArrow} alt="back-arrow" />Home</Link>
        </div>
      </div>
      <div className="list-main-wrapper f-wrp">
        <div className="container">

          <div className='value-header listViewBtn f-wrp'>
            <h1>All Listings</h1>
            <div className="list-view-switch">
              {mode !== null ? 
              <button disabled={isLoading} style={{ width: 'max-content' }} onClick={clearFilter}>
                Clear filter
              </button>
               : null}
              <button
                onClick={handleListView}
                aria-pressed={listView === 'list'}
                className={listView === 'list' ? 'active' : ''}
              >
                <SVGIcons.ListViewIcon />
              </button>
              <button
                onClick={handleGridView}
                aria-pressed={listView === 'grid'}
                className={listView === 'grid' ? 'active' : ''}
              >
                <SVGIcons.GridViewIcon />
              </button>

            </div>
          </div>

          <div className="list-con-sec f-wrp">
            {(isLoading) ? <span className='property-loader'> <SVGIcons.LoaderIcon /></span> : ''}
            {(!isLoading && data.length === 0) ? <span style={{ textAlign: 'center', display: 'block' }}>{"No Results found"} </span> : ''}

            {(!isLoading) ? <>

              {(listView === 'grid') ?
                <div className="propertyList-gridView-wrp f-wrp">
                  <PropertyListGridView propertyListArr={data || []} />
                </div> : null}
              {(listView === 'list') ?
                <div className="propertyListing-listView f-wrp">
                  {data.map((element, key) => (
                    <>
                      <div className="each-list-blk f-wrp" key={key}>
                        <Link className="absolute-btn" to={`/property-detail/${generateSlug(element.title)}`} ></Link>
                        <div className="img-gallery-wrp f-wrp">
                          {(element.images.length > 0) && <ListThumbSlider images={element.images.filter((i) => i.tempURL).map((img) => img.tempURL)} thumbnails={(element.thumbnails.length > 0) ? element.thumbnails.filter((i) => i && i.tempURL).map((img) => img?.tempURL) : element.images.filter((i) => i && i.tempURL).map((img) => img?.tempURL)} />}
                        </div>
                        <div className="list-cont-sec f-wrp">
                          <div className="prop-detail-wrp f-wrp">
                            <h1 className="prop-label">{element.title} <b>AED {element.price}</b></h1>
                            <div className="mob-price">{element.location && <span className="location"><img src={MapPinBlk} alt="map-pin" />{element.location}</span>}
                              <b><i>AED</i> {element.price}</b></div>
                            <ul className="flex-list f-wrp">
                              {element.area &&
                                <li>
                                  <span className="list-icon"><img src={ArrowsOutBlk} alt="arrowout-icon" /></span>
                                  <p>Area: <b>{element.area} sft</b></p>
                                </li>}
                              {element.bua &&
                                <li>
                                  <span className="list-icon"><img src={focusBlk} alt="focus-icon" /></span>
                                  <p>BUA: <b>{element.bua}</b></p>
                                </li>}
                              {/* {element.commision &&
                        <li>
                          <span className="list-icon"><img src={discountBlk} alt="discount-icon" /></span>
                          <p>Commision: <b>{element.commision}</b></p>
                        </li>} */}
                            </ul>
                            <div className={`split feature-list f-wrp`}>
                              {element.features && <>
                                <label>Features:</label>
                                <div>
                                  <RichTextViewer richText={element.features} />
                                </div>
                              </>}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="eachlist-contact-sec f-wrp">
                        <ul>
                          <li>
                            <div className="contct-btn-sec f-wrp">
                              <CallPopup />
                            </div>
                          </li>
                          <li>
                            <div className="contct-btn-sec f-wrp">
                              <div className="whstapp-btn" onClick={() => window.open('https://wa.me/971508880091', '_blank')}><img src={whatsap} alt="whatsapp" /><span>WhatsApp</span></div>
                            </div>
                          </li>
                          <li>
                            <div className="contct-btn-sec inquiry f-wrp">
                              <ContactPopup />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </>
                  ))}
                </div> : null}

            </> : null}







          </div>
        </div>
      </div>
    </div>
  );
};

export default Listing;
