import './App.css';
import "./assets/css/custom.css"
import AppRoutes from './AppRoutes';
import { HelmetProvider } from 'react-helmet-async';

export default function App() {
  return (
    <HelmetProvider>
      <div id='app-root'>
        <AppRoutes />

      </div>
    </HelmetProvider>
  );
}