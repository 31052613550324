import React from "react";
import AppIcons from "../../../assets/images/icons";
// import TeamLogo from "../../../assets/images/icons/teamLogo.svg";

import "./terms.scss"
import { ScrollToTopOnMount } from "../../../Components/ScrollToTop/ScrollToTop";
import ContactPopup from "../../../Components/ContactPopup/ContactPopup";


const TermsAndConditions = () => {
    ScrollToTopOnMount();

    return (
        <div className="page-main-wrapper about-main-wrapper f-wrp">
            <div className="container">
                <div className="abt-img-wrp f-wrp">
                    <span><img src={AppIcons.AbtImg} alt="propshop-logo" /></span>
                </div>
                <div className="abt-cont-wrp f-wrp">
                    <h1 className="abt-main-header">Terms and Conditions</h1>
                    <label></label>
                    <p></p>
                    <br></br>
                    <label></label>
                    <p></p>
                    <br></br>
                    <label></label>
                    <p></p>
                    <br></br>
                    <label></label>
                    <p></p>
                    <br></br>
                </div>
                <br></br>
                <div className="abt-cont-wrp f-wrp">
                    <label>QUESTIONS?</label>
                    <div className="contact-block-wrp f-wrp">
                        <ContactPopup />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
