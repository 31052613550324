import React, { useRef, useEffect, useState } from 'react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, } from 'swiper';

import SVGIcons from '../../../assets/images/icons/svgIcons';

import './teamList.scss';
import AppIcons from '../../../assets/images/icons';



function TeamList() {

  // eslint-disable-next-line react/prop-types
  const CustomPrevButton = ({ onClick }) => (
    <button type='button' className="swiper-button-prev" onClick={onClick}>
      <SVGIcons.LeftArrowIcon />
    </button>
  );
  // eslint-disable-next-line react/prop-types
  const CustomNextButton = ({ onClick }) => (
    <button type='button' className="swiper-button-next" onClick={onClick}>
      <SVGIcons.RightArrowIcon />
    </button>
  );

  const swiperRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const TestimonialsListing = [
    {
      teamMemberImage: AppIcons.Sarah_Sajwani,
      name: 'Sarah Sajwani',
      role: 'CEO & Founder'
    },
    {
      teamMemberImage: AppIcons.Muhd_Waqas,
      name: 'Muhd Waqas',
      role: 'Financial Manager'
    },
    {
      teamMemberImage: AppIcons.santiago_carnicero,
      name: 'Santiago Carnicero',
      role: 'Board Director'
    },
    // {
    //   teamMemberImage: AppIcons.Muhd_Yassir,
    //   name: 'Muhd Yassir',
    //   role: 'Sales Agent'
    // },
    
    // {
    //   teamMemberImage: AppIcons.Larisa_Bekasova,
    //   name: 'Larisa Bekasova',
    //   role: 'Sales Agent'
    // },
    // {
    //   teamMemberImage: AppIcons.Ahmad_Bin_Fadeal,
    //   name: 'Ahmad Bin Fadeal',
    //   role: 'Sales Director'
    // },
    {
      teamMemberImage: AppIcons.Sarah_Sajwani,
      name: 'Sarah Sajwani',
      role: 'CEO & Founder'
    },
    {
      teamMemberImage: AppIcons.Muhd_Waqas,
      name: 'Muhd Waqas',
      role: 'Financial Manager'
    },
    {
      teamMemberImage: AppIcons.santiago_carnicero,
      name: 'Santiago Carnicero',
      role: 'Board Director'
    },
    // {
    //   teamMemberImage: AppIcons.Muhd_Yassir,
    //   name: 'Muhd Yassir',
    //   role: 'Sales Agent'
    // },
    
    // {
    //   teamMemberImage: AppIcons.Larisa_Bekasova,
    //   name: 'Larisa Bekasova',
    //   role: 'Sales Agent'
    // },
    // {
    //   teamMemberImage: AppIcons.Ahmad_Bin_Fadeal,
    //   name: 'Ahmad Bin Fadeal',
    //   role: 'Sales Director'
    // },
    {
      teamMemberImage: AppIcons.Sarah_Sajwani,
      name: 'Sarah Sajwani',
      role: 'CEO & Founder'
    },
    {
      teamMemberImage: AppIcons.Muhd_Waqas,
      name: 'Muhd Waqas',
      role: 'Financial Manager'
    },
    {
      teamMemberImage: AppIcons.santiago_carnicero,
      name: 'Santiago Carnicero',
      role: 'Board Director'
    },
    // {
    //   teamMemberImage: AppIcons.Muhd_Yassir,
    //   name: 'Muhd Yassir',
    //   role: 'Sales Agent'
    // },
    
    // {
    //   teamMemberImage: AppIcons.Larisa_Bekasova,
    //   name: 'Larisa Bekasova',
    //   role: 'Sales Agent'
    // },
    // {
    //   teamMemberImage: AppIcons.Ahmad_Bin_Fadeal,
    //   name: 'Ahmad Bin Fadeal',
    //   role: 'Sales Director'
    // },
    {
      teamMemberImage: AppIcons.Sarah_Sajwani,
      name: 'Sarah Sajwani',
      role: 'CEO & Founder'
    },
    {
      teamMemberImage: AppIcons.Muhd_Waqas,
      name: 'Muhd Waqas',
      role: 'Financial Manager'
    },
    {
      teamMemberImage: AppIcons.santiago_carnicero,
      name: 'Santiago Carnicero',
      role: 'Board Director'
    },
    // {
    //   teamMemberImage: AppIcons.Muhd_Yassir,
    //   name: 'Muhd Yassir',
    //   role: 'Sales Agent'
    // },
    
    // {
    //   teamMemberImage: AppIcons.Larisa_Bekasova,
    //   name: 'Larisa Bekasova',
    //   role: 'Sales Agent'
    // },
    // {
    //   teamMemberImage: AppIcons.Ahmad_Bin_Fadeal,
    //   name: 'Ahmad Bin Fadeal',
    //   role: 'Sales Director'
    // },


  ];



  const teamListingRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (swiperInstance && swiperInstance.wrapperEl) {
              if (!swiperInstance.autoplay.running) {
                swiperInstance.autoplay.start();
              }
            }
          } else {
            if (swiperInstance && swiperInstance.autoplay.running) {
              swiperInstance.autoplay.stop();
            }
          }
        });
      },
      {
        threshold: 0.8,
      }
    );

    if (teamListingRef.current) {
      observer.observe(teamListingRef.current);
    }
    return () => {
      if (teamListingRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(teamListingRef.current);
      }
    };
  }, [swiperInstance]);



  return (
    <>
      <div className="item-listing-wrp serviceListing-wrp teamListing-wrp f-wrp" id="teamListing-wrp" ref={teamListingRef}>
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            setSwiperInstance(swiper);
          }}

          spaceBetween={0}
          slidesPerView={3}
          centeredSlides={true}
          // centeredSlidesBounds={true}
          className='property-slider team-slider f-wrp'
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}

          autoplay={{
            delay: 5500,
            disableOnInteraction: false,
          }}
          loop
          modules={[Navigation, Autoplay]}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 20,

            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,

            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1275: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}

        >
          {TestimonialsListing.map((element, key) => (
            <SwiperSlide key={key}>
              <div className="each-property-items each-team-members f-wrp">
                <span><img src={element.teamMemberImage} alt={element.name} /></span>
                <div className='team-title'>
                  {element.name ? <h3>{element.name}</h3> : null}
                  {element.role ? <p>{element.role}</p> : null}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <CustomPrevButton onClick={() => swiperRef.current.slidePrev()} />
        <CustomNextButton onClick={() => swiperRef.current.slideNext()} />
      </div>
    </>
  );
}

export default TeamList;