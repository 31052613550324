import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { useAtomValue } from 'jotai';
import { Button, IconButton } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import BackArrow from '../../../assets/images/icons/BackArrow.svg';
import { ScrollToTopOnMount } from "../../../Components/ScrollToTop/ScrollToTop";
import ContactPopup from "../../../Components/ContactPopup/ContactPopup";
import Lightbox from "yet-another-react-lightbox";
// eslint-disable-next-line import/no-unresolved
import "yet-another-react-lightbox/styles.css";
// eslint-disable-next-line import/no-unresolved
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
// eslint-disable-next-line import/no-unresolved
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
// eslint-disable-next-line import/no-unresolved
// import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
// eslint-disable-next-line import/no-unresolved
import Zoom from "yet-another-react-lightbox/plugins/zoom";
// eslint-disable-next-line import/no-unresolved
import "yet-another-react-lightbox/plugins/thumbnails.css";

import AppIcons from '../../../assets/images/icons';
import './blogs.scss';
import S3ImageViewerWithFallback from '../../../Components/awsS3ImageViewer/ImgViewerWithFallback';
import RichTextViewer from '../../../Components/RichText/RichTextViewer';
import ApiCaller from '../../../services/api/APICaller';
import { authState } from '../../../state/auth.state';




const IndividualBlog = () => {
  ScrollToTopOnMount();

  const { id } = useParams();
  const navigate = useNavigate();
  const auth = useAtomValue(authState)
  const [selectedArticle, setSelectedArticle] = useState({});
  const [index, setIndex] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = React.useState({});

  const [waringMessage, setWaringMessage] = useState('');

  /**
 * sets the form data 
 * @param {string} name the name of the field
 * @param {string} value  the value of the field
 */
  const setFormValue = (name, value) => {
    setFormData((prv) => ({ ...prv, [name]: value }));
  }

  /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
  const isValidForm = () => {
    let isValid = false;
    if (formData && formData.comment) {
      isValid = true;
    }
    return isValid;
  }



  useEffect(() => {
    if (id) {
      getData();
    }
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    const apiCaller = new ApiCaller('blog');
    apiCaller.getData(`/${id}`).then((data) => {
      if (data.success) {
        setSelectedArticle(data?.data)
      }
    }).finally(() => setIsLoading(false))
  }


  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      // weekday: 'long', hour: '2-digit', minute: '2-digit',second: '2-digit',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };


  const reactToBlog = () => {
    const apiCaller = new ApiCaller(`blog/${selectedArticle?.id}/react`);
    apiCaller.postData({}).then(({ data }) => {
      if (data?.blog) {
        setSelectedArticle((prv) => ({ ...prv, likes: data?.blog?.likes }))
      }
    })
  }

  const commentToBlog = () => {
    if (formData.comment) {
      const apiCaller = new ApiCaller(`blog/${selectedArticle?.id}/comment`);
      apiCaller.postData(formData).then(({ data }) => {
        if (data?.blog) {
          setSelectedArticle((prv) => ({ ...prv, comments: data?.blog?.comments }))
        }
      }).finally(() => setFormValue('comment', ''))
    }

  }

  /**
       * Calls the service and updates the comments array excluding given comment
       * @param {string} commentId the comment id to delete
       */
  const deleteComment = (commentId) => {
    const apiCaller = new ApiCaller(`blog/${selectedArticle?.id}/comment`);
    apiCaller.postData({ commentId, type: 'delete' })
      .then(({ data }) => {
        if (data.blog) {
          setSelectedArticle((prv) => ({ ...prv, comments: data?.blog?.comments }))
        }
      });
  }





  const ShortName = ({ firstName, lastName }) => {
    const getShortName = (firstName, lastName) => {
      const shortFirst = firstName ? firstName.charAt(0).toUpperCase() : '';
      // eslint-disable-next-line no-mixed-operators
      // const shortLast = lastName && lastName.charAt(0).toUpperCase() || '';
      const shortLast = lastName ? lastName.charAt(0).toUpperCase() : '';
      return shortFirst + shortLast;
    };

    const shortName = getShortName(firstName, lastName);

    return <>{shortName}</>;
  };


  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div className="page-main-wrapper about-main-wrapper article-main-wrapper detailed-article-wrp f-wrp">

      <div className="breadcrumb f-wrp">
        <div className="container">
          <Link to={"/blog"}><img src={BackArrow} alt="back-arrow" />Blogs</Link>
        </div>
      </div>

      {(!isLoading && selectedArticle) ? <div className="container">
        <div className='article-con-block f-wrp'>
          <div className='article-list-box f-wrp'>
            <span className='article-img'>
              <button className='image-popup-btn' type='button'
                onClick={() => {
                  setIndex(1)
                }}>
                <S3ImageViewerWithFallback key={`file-${selectedArticle.id}`} fileKey={selectedArticle.image?.fileKey} />
              </button>
            </span>

            {selectedArticle?.image && <Lightbox
              slides={[selectedArticle?.image.fileKey]}
              render={{
                slide: ({ slide }) => <S3ImageViewerWithFallback isShowRetry key={`file-${slide.src}`} fileKey={selectedArticle.image?.fileKey} />,
              }}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              plugins={[Fullscreen, Slideshow, Zoom]}
              controller={{ closeOnBackdropClick: 'true' }}

            />}



            <div className='article-detail-box'>
              <div className='title-with-like'>
                {auth?.isLoggedIn ? <IconButton onClick={reactToBlog} >
                  <ThumbUpIcon color={selectedArticle?.likes?.includes(auth.id) ? 'primary' : undefined} />
                </IconButton> : null}
              </div>
              <h1>{selectedArticle.title}</h1>
              <span>{selectedArticle.subtitle}</span>
              <RichTextViewer richText={selectedArticle?.description} />
              <div className='auth-box f-wrp'>
                {selectedArticle.authIcon ? <span className='icon-img'><img src={selectedArticle.authIcon ? selectedArticle.authIcon : AppIcons.auther} alt={selectedArticle.author} /></span> : null}
                <div className='auth-detail'>
                  <p>{selectedArticle.author}</p>

                  <p style={{ margin: '0', fontSize: '12px', display: 'flex', gap: '7px', alignItems: 'center' }}>
                    {auth?.isLoggedIn ?
                      <IconButton onClick={reactToBlog} >
                        <ThumbUpIcon style={{ width: '20px', height: '20px' }} color={selectedArticle?.likes?.includes(auth.id) ? 'primary' : undefined} />
                      </IconButton> : <IconButton onClick={() => { handleClickOpen(); setWaringMessage('Please login to like the post.'); }}>
                        <ThumbUpIcon style={{ width: '20px', height: '20px' }} color={selectedArticle?.likes?.includes(auth.id) ? 'primary' : undefined} />
                      </IconButton>}
                    {selectedArticle?.likes?.length} Like{selectedArticle?.likes?.length > 1 ? 's' : ''}</p>

                  <span style={{ fontSize: '12px', paddingTop: '10px', display: 'block' }}>{formatDate(selectedArticle.createdAt) !== 'Invalid Date' ? formatDate(selectedArticle.createdAt) : null}</span>
                </div>
              </div>
            </div>


            <div className='article-detail-box'>
              <span>Comments</span>
              <form className='comment-form'>

                <TextField
                  id="comment"
                  name="comment"
                  multiline
                  minRows={3}
                  fullWidth
                  value={formData.comment}
                  variant="outlined"
                  onChange={(e) => setFormValue('comment', e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#C6C6C6",
                      },
                      "&:hover fieldset": {
                        borderColor: "#C6C6C6",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#C6C6C6",
                      },
                    },
                  }}
                />
                {auth?.isLoggedIn ? <Button disabled={!isValidForm()} onClick={commentToBlog}>{'Submit'}</Button> : <Button disabled={!isValidForm()} onClick={() => { handleClickOpen(); setWaringMessage('Please login to add the comment.'); }}>{'Submit'}</Button>}
              </form>

              {/* <form className='comment-form'>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <TextField
                      id="userName"
                      name="userName"
                      placeholder='Username'
                      multiline
                      minRows={3}
                      fullWidth
                      value={formData.userName}
                      variant="outlined"
                      onChange={(e) => setFormValue('userName', e.target.value)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#C6C6C6",
                          },
                          "&:hover fieldset": {
                            borderColor: "#C6C6C6",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#C6C6C6",
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <TextField
                      id="comment"
                      name="comment"
                      fullWidth
                      value={formData.comment}
                      variant="outlined"
                      onChange={(e) => setFormValue('comment', e.target.value)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#C6C6C6",
                          },
                          "&:hover fieldset": {
                            borderColor: "#C6C6C6",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#C6C6C6",
                          },
                          "& input": {
                            height: "0.4375em",
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <TextField
                      id="comment"
                      name="comment"
                      fullWidth
                      value={formData.comment}
                      variant="outlined"
                      onChange={(e) => setFormValue('comment', e.target.value)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#C6C6C6",
                          },
                          "&:hover fieldset": {
                            borderColor: "#C6C6C6",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#C6C6C6",
                          },
                          "& input": {
                            height: "0.4375em",
                          },
                        },
                      }}
                    />
                  </div>

                </div>




                <Button disabled={!isValidForm()} onClick={commentToBlog}>{'Submit'}</Button>
              </form> */}

              <div className='comments-list f-wrp'>

                <ul>
                  {selectedArticle?.comments ? selectedArticle?.comments.slice().reverse().map((comment) => (
                    <li>



                      {auth.profilePic ? <span className='profile-pic'>
                        <S3ImageViewerWithFallback fileKey={auth.profilePic} />
                      </span> : <span className='shortName'>
                        <ShortName firstName={comment?.userId?.firstName} lastName={comment?.userId?.lastName} />
                      </span>}
                      <div className='comment-box'>
                        <p>{comment?.comment}</p>
                        <div className='comment-info'>
                          <p>{comment?.userId?.firstName}</p><p>•</p>
                          <p>{formatDate(comment?.createdAt)}</p>

                          {(auth?.id === comment?.userId?.id) ?
                            <>
                              <p>•</p>
                              <Button onClick={() => deleteComment(comment.commentId)} className='delete-btn'>Delete</Button>
                            </>
                            : null}





                        </div>
                      </div>
                    </li>

                  )) : <span>Be the first to add a comment</span>}

                </ul>

              </div>

            </div>


          </div>

        </div>
      </div> : <span>Loading...</span>}

      <br></br>
      <div className="article-cont-wrp f-wrp">
        <div className="contact-block-wrp f-wrp">
          <ContactPopup />
        </div>
      </div>

      <Dialog id="logout-modal" open={open} onClose={handleClose}>
        <DialogContent>
          <div className='logout-box f-wrp'>
            <h1>Oops!</h1>
            <p>{waringMessage}</p>
          </div>
          <div className='btn-grid comment-btns f-wrp'>
            <button type='button' onClick={handleClose}>Cancel</button>
            <button type='button' onClick={() => { navigate('/login') }}>Login</button>
          </div>
        </DialogContent>
      </Dialog>

    </div>

  );
};

export default IndividualBlog;
