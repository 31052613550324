import React, { useRef } from 'react';
import { Link } from "react-router-dom";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Navigation, Autoplay, } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import './serviceList.scss';



function ServiceList() {

  // eslint-disable-next-line react/prop-types
  const CustomPrevButton = ({ onClick }) => (
    <button type='button' className="swiper-button-prev" onClick={onClick}>
      <SVGIcons.LeftArrowIcon />
    </button>
  );
  // eslint-disable-next-line react/prop-types
  const CustomNextButton = ({ onClick }) => (
    <button type='button' className="swiper-button-next" onClick={onClick}>
      <SVGIcons.RightArrowIcon />
    </button>
  );
  const swiperRef = useRef(null);




  const serviceListing = [
    {
      serviceIcon: <SVGIcons.ServiceIcon />,
      serviceTitle: 'Pre-sale Preparation',
      Description: 'Creating a compelling sales image for an apartment to accelerate client acquisition and secure a profitable deal.',
    },
    {
      serviceIcon: <SVGIcons.ServiceIcon2 />,
      serviceTitle: 'Mortgage',
      Description: "Selecting the best mortgage program, preparing documents, and finding a property that meets the bank's criteria.",
    },
    {
      serviceIcon: <SVGIcons.ServiceIcon3 />,
      serviceTitle: 'Legal Support',
      Description: "Verifying the property's legal status, facilitating the purchase agreement, and submitting documents for state registration.",
    },
    {
      serviceIcon: <SVGIcons.ServiceIcon />,
      serviceTitle: 'Pre-sale Preparation',
      Description: 'Creating a compelling sales image for an apartment to accelerate client acquisition and secure a profitable deal.',
    },
    {
      serviceIcon: <SVGIcons.ServiceIcon2 />,
      serviceTitle: 'Mortgage',
      Description: "Selecting the best mortgage program, preparing documents, and finding a property that meets the bank's criteria.",
    },
    {
      serviceIcon: <SVGIcons.ServiceIcon3 />,
      serviceTitle: 'Legal Support',
      Description: "Verifying the property's legal status, facilitating the purchase agreement, and submitting documents for state registration.",
    },


  ];






















  return (
    <>
      <div className="item-listing-wrp blogListing-wrp serviceListing-wrp f-wrp">
        <Swiper
          onSwiper={(swiper) => { swiperRef.current = swiper; }}
          modules={[Navigation, Autoplay]}
          spaceBetween={30}
          slidesPerView={3}

          className='property-slider blog-slider f-wrp'
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 0,
              navigation: true,
              autoplay: true,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
              navigation: true,
              autoplay: true,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 20,
              navigation: false,
              autoplay: true,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 20,
              navigation: false,
            },
            1275: {
              slidesPerView: 3,
              spaceBetween: 10,
              navigation: false,
              autoplay: false,
            },
          }}

        >
          {serviceListing.map((element, key) => (
            <SwiperSlide key={key} className={`${element.className}`}>
              <div className="each-property-items f-wrp" key={key}>
                <Link to={`/`}>
                  <div className="propList-bg f-wrp">
                    {element.serviceIcon ? <span className='serviceIcon'>{element.serviceIcon}</span> : null}
                  </div>
                  <div className="propList-item-blk f-wrp">
                    {element.serviceTitle ? <h1>{element.serviceTitle}</h1> : null}
                    {element.Description ? <p>{element.Description}</p> : null}
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <CustomPrevButton onClick={() => swiperRef.current.slidePrev()} />
        <CustomNextButton onClick={() => swiperRef.current.slideNext()} />
      </div>
    </>
  );
}

export default ServiceList;