import React from 'react';
import { Stack, Typography } from '@mui/material';

import VerificationForm from '../../../forms/verificationForm';
import './auth.scss';



const RegisterPage = () => {
    // custom register functions here

    return (
        <>
            <div className='auth-page-layout auth-single-pg f-wrp'>
                <div className='container'>
                    <div className='flex-box'>
                        <div className='form-sec-wrp'>
                            <div className='auth-form f-wrp'>
                                <Stack className='form-tile' style={{ paddingBottom: '0' }}>
                                    <Typography variant="h3" fontStyle={'normal'} fontWeight={'400'}>Please enter the verification code</Typography>
                                    <Typography variant="h4" fontStyle={'normal'} fontWeight={'400'}>Your Code</Typography>
                                </Stack>
                                <VerificationForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterPage;