import * as React from 'react';
import { useAtom } from 'jotai';
import { NavHashLink } from 'react-router-hash-link';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Button } from '@mui/material';

import AppIcons from '../../../assets/images/icons';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import ContactPopup from '../../ContactPopup/ContactPopup';

import Home from '../../../assets/images/icons/home.svg';
import Listing from '../../../assets/images/icons/listing.svg';
import Aboutus from '../../../assets/images/icons/about.svg';
import ContactUs from '../../../assets/images/icons/contact.svg';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';


// import Fractional from '../../../assets/images/icons/Fractional.svg';
// import Eligibility from '../../../assets/images/icons/Eligibility.svg';
// import Traditional from '../../../assets/images/icons/Traditional.svg';
import Services from '../../../assets/images/icons/Services.svg';
import Blog from '../../../assets/images/icons/blog.svg';

import Login from '../../../assets/images/icons/login.svg';
import Logout from '../../../assets/images/icons/logout.svg';

import { logoutUser } from '../../../helpers/auth.helper';
import ProfilePopup from '../../profilePopup/profilePopup';
import { authState } from '../../../state/auth.state';
import { USER_ROLES } from '../../../constant';
import config from '../../../config/config';


// import LogoutButton from '../../../components/buttons/logout.button';
// import { translateThis } from '../../../helpers/language.helper'; 

// const drawerWidth = 240;
// const navItems = ['Home', 'Properties', 'About Us', 'Reviews', 'Contacts'];

const navItems = [
  {
    title: 'Home',
    key: 'home',
    icon: Home,
    link: '/'
  },
  {
    title: 'See all listings',
    key: 'listings',
    icon: Listing,
    link: '/exclusive-listings'
  },
  {
    title: 'Create / manage listings',
    key: 'listings',
    icon: Listing,
    // link: '/exclusive-listings'
    customLink: config.adminAppURL
  },
  {
    title: 'Services',
    key: 'services',
    icon: Services,
    hashLink: '/traditional/#Services'
  },
  {
    title: 'About us',
    key: 'about-us',
    icon: Aboutus,
    link: '/about-us'
  },
  {
    title: 'Blogs',
    key: 'blogs',
    icon: Blog,
    link: '/blog'
  },
  {
    title: 'Contact us',
    key: 'contact-us',
    icon: ContactUs,
    link: '/contact-us',
  },
  {
    title: 'Login / Register',
    key: 'login',
    icon: Login,
    link: '/login'
  },
];

const pcNavItems = [
  {
    title: 'Home',
    link: '/',
  },
  {
    title: 'Properties',
    link: 'exclusive-listings',
  },
  {
    title: 'About Us',
    link: 'about-us',
  },
  {
    title: 'Insights',
    link: 'blog',
  },
  {
    title: 'Reviews',
    hashLink: '/#reviews',
  },
];




const buttonItems = [
  // {
  //   title: 'Find a Broker',
  //   key: 'find-broker',
  //   icon: <SVGIcons.HomeIcon />,
  //   message: ''
  // },
  {
    title: 'Request a Callback',
    key: 'request-callback',
    icon: <SVGIcons.CallBackIcon />,
    message: "Please call me back. I'm interested in...",
  },
];

function LandingDrawer(props) {

  const { window } = props;
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [auth, setAuth] = useAtom(authState);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleDrawerToggle2 = () => {
    setMobileOpen(false);
  };

  const handleLogoutUser = () => {
    setAuth({})
    setOpen(false);
    logoutUser();
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const checkIsVisible = (item) => {
    let isVisible = false;
    const isUserLoggedIn = auth.isLoggedIn;

    switch (item) {
      case 'home':
        const homePageUrl = window?.location.origin + '/';
        isVisible = window?.location.href !== homePageUrl;
        break;

      case 'login':
        isVisible = !isUserLoggedIn;
        break;

      case 'register':
        isVisible = !isUserLoggedIn;
        break;

      case 'logout':
        isVisible = isUserLoggedIn;
        break;

      default:
        isVisible = true
        break;
    }
    return isVisible;
  }




  const drawer = (
    <Box onClick={handleDrawerToggle2} sx={{ textAlign: 'center' }} className='mob-drawer-revamp'>
      <div>
        <Box className="landing-logo">
          {checkIsVisible('logout') ? <ProfilePopup /> : <Link to={'/'}>
            <img src={AppIcons.homeLogo} alt='logo' />
          </Link>}
          <div className="close-btn f-wrp">
            <span>&#10006;</span>
          </div>
        </Box>

        <Box className="nav-list" sx={{ display: { xs: 'none', sm: 'block' } }}>
          <div className='link-btn'>
            {/* {navItems.map((item) => (
            <NavHashLink key={item} smooth to={`#${item}`} >
              {item}
            </NavHashLink>
          ))} */}
            {navItems.filter((i) => checkIsVisible(i.key)).map((element, key) => (
              <React.Fragment key={key}>

                {element.customLink ? <a href={`${element.customLink}`} target="_blank" rel="noreferrer" style={{ width: '100%', display: 'flex', gap: '5px' }} >
                  <span className='menu-icon'><img src={element.icon} alt="Menu-icon" /></span>
                  <Button className="nav-link-btn" key={element.link} sx={{ color: '#fff' }}>
                    {element.title}
                  </Button>
                </a> : (element.hashLink ? <NavHashLink smooth to={`${element.hashLink}`} style={{ width: '100%', display: 'flex', gap: '5px' }} >
                  <span className='menu-icon'><img src={element.icon} alt="Menu-icon" /></span>
                  <Button className="nav-link-btn" key={element.link} sx={{ color: '#fff' }}>
                    {element.title}
                  </Button>
                </NavHashLink> : <Link to={`${element.link}`} style={{ width: '100%', display: 'flex', gap: '5px' }} >
                  <span className='menu-icon'><img src={element.icon} alt="Menu-icon" /></span>
                  <Button className="nav-link-btn" key={element.link} sx={{ color: '#fff' }}>
                    {element.title}
                  </Button>
                </Link>)}
              </React.Fragment>
            ))}


            {checkIsVisible('register') ? <Button onClick={() => {
              setAuth({ userRole: USER_ROLES.EXTERNAL_AGENT });
              navigate('/register')

            }} style={{ width: '100%', display: 'flex', gap: '5px', justifyContent: 'flex-start', padding: '0' }} >
              <span className='menu-icon'><img src={Login} alt="Menu-icon" /></span>
              <Button className="nav-link-btn" key={'/login'} sx={{ color: '#fff' }}>
                Register as an agent
              </Button>
            </Button> : null}
            {checkIsVisible('logout') ? <Link to={`/`} onClick={handleClickOpen} style={{ width: '100%', display: 'flex', gap: '5px' }} >
              <span className='menu-icon'><img src={Logout} alt="Menu-icon" /></span>
              <Button className="nav-link-btn" key={'/login-out'} sx={{ color: '#fff' }}>
                Logout
              </Button>
            </Link> : null}
            {/* {checkIsVisible('') ? <button type='button' ></button> : null} */}

          </div>

        </Box>
      </div>
      <div>
        <Box className="nav-list" sx={{ display: { xs: 'none', sm: 'block' } }}>
          <div className='box-btn'>
            {buttonItems.map((element, key) => (
              <div className='head-btn-box' key={key} >
                {element.message ? <ContactPopup icon={element.icon} title={element.title} defaultComment={element.message} /> :
                  <Link className='head-btn' to={'/'} key={key}>
                    {element.icon}
                    {element.title}
                  </Link>}
              </div>
            ))}

          </div>
        </Box>
      </div>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (

    <AppBar component="nav" className='header-revamp-wrp' position='sticky'>
      <div className='container'>
        <Box className="landing-navbar" sx={{ display: 'flex' }}>
          <Toolbar className='main-Toolbar'>
            <Box className="landing-logo">
              <Link to={'/'}>
                <img src={AppIcons.homeLogo} alt='logo' />
              </Link>
            </Box>

            <Box className="nav-list" sx={{ display: { xs: 'none', sm: 'block' } }}>
              <div className='f-ww'>
                {pcNavItems.map((element, key) => (
                  <React.Fragment key={key}>
                    {(element.hashLink ? <NavHashLink smooth to={`${element.hashLink}`}>
                      {element.title}
                    </NavHashLink> : <Link to={`${element.link}`}>
                      {element.title}
                    </Link>)}
                  </React.Fragment>
                ))}

                <ContactPopup />
              </div>
              <div className='f-wr'>
                {buttonItems.map((element, key) => (
                  <div className='head-btn-box' key={key}>
                    {element.message ? <ContactPopup icon={element.icon} title={element.title} defaultComment={element.message} /> :
                      <Link className='head-btn' to={'/'} key={key}>
                        {element.icon}
                        {element.title}
                      </Link>}
                  </div>

                ))}
                <IconButton
                  className='mob-icon'
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  style={{ margin: '0', padding: '0' }}
                  sx={{ mr: 2, display: { sm: 'block' } }}
                >
                  <div className="logo-wrp f-wrp">
                    <span>
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.33337 9.33337H9.33337M26.6667 9.33337H14.6667M26.6667 22.6667H22.6667M5.33337 22.6667H17.3334M5.33337 16H26.6667" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" />
                      </svg>
                    </span>
                  </div>
                </IconButton>
              </div>


            </Box>
          </Toolbar>
        </Box>

        <div className='mob-navBar f-wrp'>
          <Box className="landing-logo">
            <Link to={'/'}>
              <img src={AppIcons.homeLogo} alt='logo' />
            </Link>
          </Box>
          <IconButton
            className='mob-icon'
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'block' } }}
          >
            <div className="logo-wrp f-wrp">
              <span>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.33337 9.33337H9.33337M26.6667 9.33337H14.6667M26.6667 22.6667H22.6667M5.33337 22.6667H17.3334M5.33337 16H26.6667" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </span>
            </div>
          </IconButton>
        </div>
      </div>




      <Box sx={{ display: 'flex' }}>
        <Box component="nav">
          <Drawer
            className='mob-draw-wrp-revamp'
            container={container}
            variant="temporary"
            open={mobileOpen}
            anchor='right'
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { xs: '100%', sm: 350 } },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>




      <Dialog id="logout-modal" open={open} onClose={handleClose}>
        <DialogContent>
          <div className='logout-box f-wrp'>
            <h1>Confirm logout</h1>
            <p>Are you sure you want to logout?</p>
          </div>
          <div className='btn-grid f-wrp'>
            <button type='button' onClick={handleClose}>Cancel</button>
            <button type='button' onClick={handleLogoutUser}>Logout</button>
          </div>
        </DialogContent>
      </Dialog>
    </AppBar>
  );
}

LandingDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default LandingDrawer;