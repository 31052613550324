import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';
import AppIcons from '../../assets/images/icons';
import './home.scss';
import SVGIcons from '../../assets/images/icons/svgIcons';
import FilterForm from './filterForm/filterForm';
import PropertyList from './propertyList/propertyList';
import BlogList from './blogList/blogList';
import ServiceList from './serviceList/serviceList';
import ReviewList from './reviewList/reviewList';
import TeamList from './teamList/teamList';





const HomeRevamp = () => {
    const navigate = useNavigate()
    return (
        <div className='home-main-wrp f-wrp' id='home'>
            <div className='banner-wrp f-wrp'>
                <span className='bannerBg'>
                    <img src={AppIcons.homeBannerBG} alt='banner' />
                </span>

                <div className='banner-content-box f-wrp' >
                    <div className='container'>
                        <div className='banner-content f-wrp'>
                            <h1>Empowering Smarter Investments</h1>
                            <div className='filter-box f-wrp'>
                                <div className='filter-head'>
                                    <h4>Find Your Property</h4>
                                    <Button onClick={()=>navigate(`/exclusive-listings?mode=filtered`)} className='search-btn'><SVGIcons.SearchIcon /> Search</Button>
                                </div>
                                <div className='filter-form'>
                                    <FilterForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='each-home-sec property-listing-wrp f-wrp' id='properties'>
                <div className='container'>
                    <div className='property-listing-sec f-wrp'>
                        <div className='section-header f-wrp'>
                            <div className='container'>
                                <p>CHECKOUT OUR TOP LISTS</p>
                                <h1>Top Listed Properties</h1>
                            </div>
                        </div>
                        <PropertyList />
                        <Link to={'/exclusive-listings'} className='custom-pln-btn'>See all listings</Link>
                    </div>
                </div>
            </div>

            <div className='each-home-sec blog-listing-wrp f-wrp' id='blogs'>
                <div className='container'>
                    <div className='property-listing-sec f-wrp'>
                        <div className='section-header f-wrp'>
                            <div className='container'>
                                <p>tips, guides & trends</p>
                                <h1>Propshop’s Blogs & Posts</h1>
                            </div>
                        </div>
                        <BlogList />
                        <Link to={'/blog'} className='custom-pln-btn'>See more insights</Link>
                    </div>
                </div>
            </div>

            <div className='each-home-sec service-listing-wrp f-wrp'>
                <div className='container'>
                    <div className='property-listing-sec f-wrp'>
                        <div className='section-header f-wrp'>
                            <div className='container'>
                                <h1>Our Services</h1>
                            </div>
                        </div>
                        <ServiceList />
                    </div>
                </div>
            </div>

            <div className='each-home-sec review-listing-wrp f-wrp' id='reviews'>
                <div className='container'>
                    <div className='property-listing-sec f-wrp'>
                        <div className='section-header f-wrp'>
                            <div className='container'>
                                <p>Reviews About our Company</p>
                                <h1>Recent Reviews</h1>
                            </div>
                        </div>
                        <ReviewList />
                    </div>
                </div>
            </div>

            <div className='each-home-sec team-listing-wrp f-wrp' id='About-Us'>
                <div className='container'>
                    <div className='team-listing-sec f-wrp'>
                        <div className='section-header f-wrp'>
                            <div className='container'>
                                <p>introducing</p>
                                <h1>The Team</h1>
                            </div>
                        </div>
                        <TeamList />
                    </div>
                </div>
            </div>

            <div className='each-home-sec agent-required-wrp f-wrp' id='become-an-agent'>
                <div className='container'>
                    <div className='agent-required-box f-wrp'>
                        <div className='img-blk'>
                            <span>
                                <img src={AppIcons.Sarah} alt='Sarah' />
                            </span>
                        </div>
                        <div className='content-sec'>
                            <div className='content-box'>
                                <h1>Become our Agent.</h1>
                                <p>Become a sales agent with us and unlock exciting opportunities in real estate while learning from the best team in the industry!</p>
                            </div>
                            {/* <button className='reg-btn'>Register Now</button> */}
                            <Link to={'/register'} className='reg-btn'>Register Now</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='f-wrp' id='contacts' />

        </div>
    )
}

export default HomeRevamp;