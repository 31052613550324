import { useState } from 'react';
import { useSetAtom } from 'jotai';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import { Stack, FormControlLabel, Checkbox, Button } from '@mui/material';
// components
import { login } from '../services/api/auth';
import { showSnackbar } from '../Components/Notification/Snackbar';
import TextInput from '../Components/inputs/textInput/textInput';
import { PasswordRequirementCheckBoxes } from '../Components/inputs/textInput/password.requirements';
import { ScrollToTopOnMount } from "../Components/ScrollToTop/ScrollToTop";
import './form.scss';
import { authState } from '../state/auth.state';
import { USER_ROLES } from '../constant';
// ----------------------------------------------------------------------

export default function LoginForm() {
    const navigate = useNavigate();
    const setAuth = useSetAtom(authState);
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isRememberMe, setIsRememberMe] = useState(false);
    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState({
        eightCharacter: false,
        containNumber: false,
        containUpperCase: false,
        containSpecialChar: false
    });

    /**
    * To validate password requirement
    * @param {String} password 
    */
    const validatePassword = (password) => {
        const rules = {
            eightCharacter: false,
            containNumber: false,
            containUpperCase: false,
            containSpecialChar: false
        }

        if (password.length > 7) { // To check if password contain 8 chars
            rules.eightCharacter = true;
        }
        if (/\d/.test(password)) { // To check if password contain numbers
            rules.containNumber = true;
        }
        if (password !== password.toLowerCase()) { // To check if password contain uppercase
            rules.containUpperCase = true;
        }
        const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
        if (format.test(password)) { // To check if password contain special chars
            rules.containSpecialChar = true;
        }
        setValidPassword(rules)
        if (rules.containNumber &&
            rules.containSpecialChar &&
            rules.containUpperCase &&
            rules.eightCharacter
        ) {
            setPassword(password)
        } else {
            setPassword('')
        }
    }

    const onSubmit = () => {
        setIsLoading(true);
        login({ email, password, isRememberMe }).then((data) => {
            if (data.success) {
                setAuth({...data?.data, isLoggedIn: true});
                navigate('/', { replace: true });
                showSnackbar().success(data.message)
            } else {
                showSnackbar().failure(data.message)

            }
        }).finally(() => setIsLoading(false));
    };

    ScrollToTopOnMount();


    return (
        <>
            <Stack className='login-form standard-form' spacing={3}>

                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <TextInput
                            key={`login-email`}
                            label='Email'
                            name='email'
                            type='email'
                            required
                            placeholder='Please enter your email address'
                            className='custom-textfield'
                            getValue={(_, value) => setEmail(value)}
                            size='small'

                        />
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <TextInput
                            key={`login-password`}
                            label='Password'
                            name='password'
                            type='password'
                            isRequired={false}
                            placeholder='Please enter your password'
                            className='custom-textfield'
                            getValue={(_, value) => validatePassword(value)}
                            size='small'

                        />
                    </div>

                </div>

                <PasswordRequirementCheckBoxes rules={validPassword} />
                <Stack className='additional-btn' variant='div' spacing={3} style={{ marginTop: '0' }}>
                    <FormControlLabel className='rememberMe' control={<Checkbox color='primary' onChange={(e) => setIsRememberMe(e.target.checked)} />} label="Remember me" />
                    <p style={{ textAlign: 'right' }}><Link to={'/forgot-password'}>Forgot your password?</Link></p>
                </Stack>
                <Button
                    className='auth-btn'
                    onClick={() => onSubmit()}
                    disabled={!email || !password}
                >
                    {isLoading ? 'Logging you in...' : 'Login'}
                </Button>

            </Stack>
            <Stack className='additional-btn' variant='div' spacing={3}>
                <p className='flexP'>Don’t have an account yet?
                    {/* <Link to={'/register'}>Sign up</Link> */}
                    <Button onClick={() => {
                        setAuth({ userRole: USER_ROLES.USER });
                        navigate('/register')
                    }} style={{ padding: '0', textTransform: 'none' }} >
                        Sign up
                    </Button>

                </p>
            </Stack>
        </>
    );
}
