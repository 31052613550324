import React from 'react';
// import Main from '../Main';
import Home from '../Main/home';

const LandingPage = () => {
    const isLoggedIn = true;
    return (
        <div>
            {(isLoggedIn) ? <>

                <Home />
            </> : <div>Login section to implement</div>}
        </div>
    )
}

export default LandingPage;