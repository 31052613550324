import React from 'react';
import { Link } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { Stack, Typography } from '@mui/material';
import AppIcons from '../../../assets/images/icons/index';
import { ScrollToTopOnMount } from "../../../Components/ScrollToTop/ScrollToTop";
import './auth.scss';
import LoginForm from '../../../forms/login.form';
import { authState } from '../../../state/auth.state';



const LoginPage = () => {

    const auth = useAtomValue(authState);

    // const location = useLocation();
    // const isAuth = auth.isLoggedIn;
    // const { from } = location.state || { from: { pathname: `/` } };
    // if (isAuth) {
    //     // navigate(from, { replace: true });
    // }


    ScrollToTopOnMount();

    return (
        <>
            <div className={`login-wrp register-wrp auth-layout`}>
                <div className='auth-page-layout f-wrp'>
                    <div className={`flex-box ${auth.userRole ? 'form-open' : 'form-close'}`}>
                        <div className='form-sec-wrp' style={{ position: 'relative' }}>
                            

                            {<div className='auth-form'>
                                    <Link to={'/'} className='auth-logo'><img src={AppIcons.AbtImg} alt='Propshop' /></Link>
                                    <Stack className='form-tile'>
                                        <Typography variant="h3" fontStyle={'normal'} fontWeight={'300'}>Login</Typography>
                                    </Stack>
                                    <LoginForm />
                                </div>}
                        </div>
                    </div>
                </div>
                <div className='form-img-wrp'>
                    <span><img src={AppIcons.banner} alt='register' /></span>
                </div>
            </div>
        </>
    )
}

export default LoginPage;