import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./sliderThumb.css";

import Lightbox from "yet-another-react-lightbox";
// eslint-disable-next-line import/no-unresolved
import "yet-another-react-lightbox/styles.css";
// eslint-disable-next-line import/no-unresolved
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
// eslint-disable-next-line import/no-unresolved
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
// eslint-disable-next-line import/no-unresolved
// import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
// eslint-disable-next-line import/no-unresolved
import Zoom from "yet-another-react-lightbox/plugins/zoom";
// eslint-disable-next-line import/no-unresolved
import "yet-another-react-lightbox/plugins/thumbnails.css";



// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";

export default function SliderThumbPage({ images = [], thumbnails = [] }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const sliderImages = images.filter((img) => {
    const isThumbnail = thumbnails.find((i) => i.fileKey === img.fileKey);
    return isThumbnail !== undefined;
  });
  const sliderImagesWithThumbnails = thumbnails.concat(sliderImages);

  const propertyImageSlider = sliderImagesWithThumbnails.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });


  const [index, setIndex] = useState(-1);
  const [selectedPhoto, setSelectedPhoto] = useState([])

  useEffect(() => {
    if (propertyImageSlider) {
      setSelectedPhoto(propertyImageSlider)
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {images?.length > 0 ? <div className="row">
        <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12" style={{ paddingLeft: '0', paddingRight: '0' }}>
          <Swiper
            style={{
              "--swiper-navigation-color": "#C6C6C6",
              "--swiper-pagination-color": "#C6C6C6",
            }}
            initialSlide={1}
            loop={true}
            spaceBetween={25}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
          >
            {propertyImageSlider.map((element, key) => (
              <SwiperSlide key={key}>
                <button className='image-popup-btn' type='button'
                  onClick={() => {
                    setIndex(key)
                  }}>
                  <img src={element} alt="property" />
                </button>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12" style={{ paddingRight: '0', paddingLeft: '0', }}>
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            initialSlide={1}
            direction="vertical"
            spaceBetween={7}
            slidesPerView={3}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
          >
            {propertyImageSlider.map((img, key) => (
              <SwiperSlide key={key}>
                <img src={img} alt="property" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div> : null}



      {selectedPhoto && <Lightbox
        // slides={selectedPhoto}
        // render={{
        //   slide: ({ slide }) => <img src={slide} alt="property" />,
        // }}
        slides={propertyImageSlider.map((image) => ({ src: image }))}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={[Fullscreen, Slideshow, Zoom]}
        controller={{ closeOnBackdropClick: 'true' }}

      />}


    </>
  );
}
