import * as React from 'react';
import { useAtomValue } from 'jotai';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import './profilePopup.scss'
import AppIcons from '../../assets/images/icons';
import { authState } from '../../state/auth.state';
import S3ImageViewerWithFallback from '../awsS3ImageViewer/ImgViewerWithFallback';


function ProfilePopup() {

  const [open, setOpen] = React.useState(false);
  const auth = useAtomValue(authState);
  // eslint-disable-next-line
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {auth.isLoggedIn ? <button className='profile-info-wrp-btn' type='button' onClick={()=>null}>
        <div className='profile-info-wrp f-wrp'>
          {auth.profilePic ? <span>
            <S3ImageViewerWithFallback fileKey={auth.profilePic} />
          </span> : null}
          <div className='detail-wrp'>
            <h3>{auth?.firstName} {auth?.lastName}</h3>
            <p>{auth?.email}</p>
            <p style={{ textTransform: 'capitalize' }}>{auth?.role}</p>
          </div>
        </div>
      </button> : null}

      <Dialog id="profile-modal" open={open} onClose={handleClose}>
        <DialogContent>
          <DialogActions className='float-btn'>
            <span onClick={handleClose}><svg width="25" height="25" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.53341 25.8334L6.66675 23.9667L14.1334 16.5001L6.66675 9.03341L8.53341 7.16675L16.0001 14.6334L23.4667 7.16675L25.3334 9.03341L17.8667 16.5001L25.3334 23.9667L23.4667 25.8334L16.0001 18.3667L8.53341 25.8334Z" fill="#FFFFFF" />
            </svg>
            </span>
          </DialogActions>
          <div className='profile-grid-wrp f-wrp'>
            <div className='prof-lft-box'>
              <span className='prof-img'>
                <img src={AppIcons.MobBannerLogo} alt='name' />
              </span>
              <span className='label'>Admin</span>
            </div>

            <div className='detail-wrp f-wrp'>
              <div className='name-box'>
                <h3>Name: <b>First Name</b></h3>
                <h3>Last name: <b>Last Name</b></h3>
              </div>
              <div className='basic-details f-wrp'>
                <p>Email: <b>mail@mail.com</b></p>
                <p>Phone: <b>3698521470</b></p>
                <p>Address: <b>Address line 1, Address line 2</b></p>
                <p>DOB: <b>11/11/1999</b> &nbsp; &nbsp; &nbsp; Age: <b>21</b> &nbsp; &nbsp; &nbsp; Gender: <b>gender</b> </p>
                <p>State: <b>state</b> &nbsp; &nbsp; &nbsp; City: <b>city</b> &nbsp; &nbsp; &nbsp; ZipCode: <b>123654</b> </p>
                <p>Note: <b>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </b></p>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ProfilePopup