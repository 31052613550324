import { React} from "react";
import { Link } from "react-router-dom";
import MapPinBlk from '../../../assets/images/icons/MapPinBlk.svg';
import ArrowsOutBlk from '../../../assets/images/icons/ArrowsOutBlk.svg';
import focusBlk from '../../../assets/images/icons/focusBlk.svg';
import whatsap from '../../../assets/images/icons/whatsap.svg';
import "./listing.scss"
import CallPopup from "../../../Components/ContactPopup/CallPopup";
import ContactPopup from "../../../Components/ContactPopup/ContactPopup";
import ListThumbSlider from "../../../Components/ListThumbSlider";

import SVGIcons from "../../../assets/images/icons/svgIcons";

const PropertyListGridView = ({ propertyListArr = [] }) => {
  
  
  const generateSlug = (title) => {
    return title
      .replace(/\s+/g, '_') // Replace spaces with dashes
  };


  return (
    <div className="propertyList-gridView f-wrp">
        <div className="row">
          {propertyListArr.map((element, key) => (
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12"  key={key}>
              <div className="eachGrid-box each-list-blk f-wrp">
                <Link className="absolute-btn" to={`/property-detail/${generateSlug(element.title)}`} ></Link>
                <div className="img-gallery-wrp slider-box f-wrp">
                  {(element.images.length > 0) && <ListThumbSlider images={element.images.filter((i) => i.tempURL).map((img) => img.tempURL)} thumbnails={(element.thumbnails.length > 0) ? element.thumbnails.filter((i) => i && i.tempURL).map((img) => img?.tempURL) : element.images.filter((i) => i && i.tempURL).map((img) => img?.tempURL)} />}
                </div>
                <div className="list-cont-sec f-wrp">
                  <div className="prop-detail-wrp f-wrp">
                    <label className="prop-label"><b>AED {element.price}</b></label>
                    <div className="mob-price">{element.location && <span className="location"><img src={MapPinBlk} alt="map-pin" />{element.location}</span>}
                      <b><i>AED</i> {element.price}</b></div>
                    <ul className="flex-list f-wrp">
                      {element.area &&
                        <li>
                          <span className="list-icon"><img src={ArrowsOutBlk} alt="arrowout-icon" /></span>
                          <p>Area: <b>{element.area} sft</b></p>
                        </li>}
                      {element.bua &&
                        <li>
                          <span className="list-icon"><img src={focusBlk} alt="focus-icon" /></span>
                          <p>BUA: <b>{element.bua} sft</b></p>
                        </li>}
                      {element.noOfBedrooms &&
                        <li>
                          <span className="list-icon"><SVGIcons.BedIcon /></span>
                          <p>Beds: <b>{element?.noOfBedrooms}</b></p>
                        </li>}
                      {element.noOfBathrooms &&
                        <li>
                          <span className="list-icon"><SVGIcons.BathIcon /></span>
                          <p>Baths: <b>{element?.noOfBathrooms}</b></p>
                        </li>}
                    </ul>
                  </div>
                </div>
                <div className="eachlist-contact-sec f-wrp">
                  <ul>
                    <li>
                      <div className="contct-btn-sec f-wrp">
                        <CallPopup />
                      </div>
                    </li>
                    <li>
                      <div className="contct-btn-sec f-wrp">
                        <div className="whstapp-btn" onClick={() => window.open('https://wa.me/971508880091', '_blank')}><img src={whatsap} alt="whatsapp" /><span>WhatsApp</span></div>
                      </div>
                    </li>
                    <li>
                      <div className="contct-btn-sec inquiry f-wrp">
                        <ContactPopup />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
    </div>
  );
};

export default PropertyListGridView;
